import { withRouter } from "react-router-dom";

const SingelSellerGuide = ({ element }) => {
  return (
    <div className="sb-admin no-shadow">
      <section className="request-data">
        <span>Name:</span>
        <span>{element.name}</span>
      </section>
      <section className="request-data">
        <span>Email address:</span>
        <span>
          <a href={`mailto:${element.name}`}>{element.email}</a>
        </span>
      </section>
      <section className="request-data">
        <span>Phone number:</span>
        <span>
          <a href={`tel:${element.phone}`}>{element.phone}</a>
        </span>
      </section>
      <section className="request-data">
        <span>Help type:</span>
        <span>
          {element.moreInfo && (
            <section>
              <p>{element.moreInfo}</p>
            </section>
          )}
          {element.priveteSession && (
            <section>
              <p>{element.priveteSession}</p>
            </section>
          )}
          {element.propertyAssessed && (
            <section>
              <p>{element.propertyAssessed}</p>
            </section>
          )}
          {element.visit && (
            <section>
              <p>{element.visit}</p>
            </section>
          )}
        </span>
      </section>
      {element.propertyAddress && (
        <section className="request-data">
          <span>Property address:</span>
          <span>{element.propertyAddress}</span>
        </section>
      )}
      {element.notes && (
        <section className="request-data">
          <span>Notes:</span>
          <span>{element.notes}</span>
        </section>
      )}
    </div>
  );
};

export default withRouter(SingelSellerGuide);
