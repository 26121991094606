import React from "react";
import SellerContainer from "./SellerContainer";

import buyer from "../../../images/buyer.png";
import { useState } from "react";
import { buyerGuideReducer } from "../sellerBuyerSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import back from "../../../images/home.jpg";

const BuyerGuide = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sellerBuyer.isLoading);

  const [buyerGuide, setBuyerGuide] = useState({
    name: "",
    email: "",
    phone: "",
    secteur: "",
    property: "",
    other: "",
    details: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const handleChange = ({ currentTarget }) => {
    const errorsObj = { ...errors };
    if (errorsObj[currentTarget.name] && currentTarget.value) {
      errorsObj[currentTarget.name] = "";
      setErrors(errorsObj);
    } else if (errorsObj[currentTarget.name] === "" && !currentTarget.value) {
      errorsObj[currentTarget.name] = "error";
      setErrors(errorsObj);
    }
    setBuyerGuide((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = () => {
    const errorsObj = { ...errors };
    let isErrors = false;
    for (let element in buyerGuide) {
      if (element === "name" || element === "phone" || element === "email") {
        if (!buyerGuide[element]) {
          errorsObj[element] = "error";
          isErrors = true;
        }
      }
    }

    if (isErrors) toast.error("please fill the required fields");
    setErrors(errorsObj);

    if (!isErrors)
      dispatch(buyerGuideReducer(buyerGuide))
        .unwrap()
        .then(() => {
          toast.success("Your information was sent successfully");
          setBuyerGuide({
            name: "",
            email: "",
            phone: "",
            secteur: "",
            property: "",
            other: "",
            details: "",
          });
        });
  };

  const { sellerBuyer } = getLanguageObject();

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <SellerContainer
      bg={image.buyerGuide ? image.buyerGuide : loadedImages ? back : ""}
      bgcolor="#fff"
    >
      <ToastContainer />
      <div className="seller-buyer">
        <header>{sellerBuyer.buyerGuide}</header>
        <p>{sellerBuyer.buyerGuidePar}</p>
        <a
          href="https://www.oaciq.com/uploads/ckeditor/attachments/401/buyers-guide.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img src={buyer} alt="buyer's guide" />
        </a>
        <h6>* {sellerBuyer.clickOnImg}</h6>
        <div className="form">
          <p className="primary">{sellerBuyer.access}</p>
          <p className="secondary">{sellerBuyer.lookingFor}</p>
          <section className={`input ${errors.name ? "error" : ""}`}>
            <label>
              {sellerBuyer.name} <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              value={buyerGuide.name}
              onChange={handleChange}
            />
          </section>
          <section className={`input ${errors.email ? "error" : ""}`}>
            <label>
              {sellerBuyer.email} <span>*</span>
            </label>
            <input
              type="text"
              name="email"
              value={buyerGuide.email}
              onChange={handleChange}
            />
          </section>
          <section className={`input ${errors.phone ? "error" : ""}`}>
            <label>
              {sellerBuyer.phone} <span>*</span>
            </label>
            <input
              type="text"
              name="phone"
              value={buyerGuide.phone}
              onChange={handleChange}
            />
          </section>
          <section className="input">
            <label>{sellerBuyer.research}</label>
            <input
              type="text"
              name="secteur"
              value={buyerGuide.secteur}
              onChange={handleChange}
            />
          </section>
          <section className="input">
            <label>{sellerBuyer.typeOfProperty}</label>
            <div>
              <input
                type="radio"
                name="property"
                value="House"
                onChange={handleChange}
              />
              <p>House</p>
            </div>
            <div>
              <input
                type="radio"
                name="property"
                value="Duplex"
                onChange={handleChange}
              />
              <p>Duplex</p>
            </div>
            <div>
              <input
                type="radio"
                name="property"
                value="Triplex"
                onChange={handleChange}
              />
              <p>Triplex</p>
            </div>
            <div>
              <input
                type="radio"
                name="property"
                value="Multi-housing"
                onChange={handleChange}
              />
              <p>Multi-housing</p>
            </div>
            <div>
              <input
                type="radio"
                name="property"
                value="Farms"
                onChange={handleChange}
              />
              <p>Farms</p>
            </div>
            <div>
              <input
                type="radio"
                name="property"
                value="Commercial"
                onChange={handleChange}
              />
              <p>Commercial</p>
            </div>
            <div>
              <input
                type="radio"
                name="property"
                value="Industrial"
                onChange={handleChange}
              />
              <p>Industrial</p>
            </div>
          </section>
          <section className="input">
            <label>{sellerBuyer.other}</label>
            <input
              type="text"
              name="other"
              value={buyerGuide.other}
              onChange={handleChange}
            />
          </section>
          <section className="input">
            <label>{sellerBuyer.details}</label>
            <textarea
              rows={4}
              name="details"
              value={buyerGuide.details}
              onChange={handleChange}
            ></textarea>
          </section>
          <button onClick={handleSubmit} disabled={isLoading}>
            {isLoading && <div className="main-loader"></div>}
            {!isLoading && (
              <>
                <i className="lar la-paper-plane" />
                {sellerBuyer.send}
              </>
            )}
          </button>
        </div>
      </div>
    </SellerContainer>
  );
};

export default BuyerGuide;
