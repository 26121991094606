import http from "./http";
import url from "../config/server.json";

const baseUrl = url.baseUrl;
export const sendTestimonialApi = (data) => {
  const route = `${baseUrl}/testimonial`;
  data = {
    title: data.title,
    name: data.name,
    testimonial: data.testimonial,
  };
  return http.post(route, data);
};

export const getTestimonialApi = (args) => {
  const route = `${baseUrl}/testimonial?status=${args}`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getApprovedTestimonialApi = () => {
  const route = `${baseUrl}/approvedTestimonial`;
  return http.get(route);
};

export const approveTestimonialApi = (id, result) => {
  const route = `${baseUrl}/testimonial/${id}`;
  return http.patch(
    route,
    {
      approved: result,
    },
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
};
