import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  buyerGuideApi,
  findHomeValueApi,
  findPropertyApi,
  sellerGuideApi,
} from "../../api/sellerBuyerApi";

export const sellerGuideReducer = createAsyncThunk(
  "sellerBuyer/sellerGuide",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await sellerGuideApi(args);
      return;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const findHomeValueReducer = createAsyncThunk(
  "sellerBuyer/findHomeValue",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await findHomeValueApi(args);
      return;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const buyerGuideReducer = createAsyncThunk(
  "sellerBuyer/buyerGuide",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await buyerGuideApi(args);
      return;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const findPropertyReducer = createAsyncThunk(
  "sellerBuyer/findProperty",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await findPropertyApi(args);
      return;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

const sellerBuyerSlice = createSlice({
  name: "sellerBuyer",
  initialState: {
    isLoading: false,
  },
  extraReducers: {
    [sellerGuideReducer.pending]: (state) => {
      state.isLoading = true;
    },
    [sellerGuideReducer.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [sellerGuideReducer.rejected]: (state) => {
      state.isLoading = false;
    },

    [findHomeValueReducer.pending]: (state) => {
      state.isLoading = true;
    },
    [findHomeValueReducer.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [findHomeValueReducer.rejected]: (state) => {
      state.isLoading = false;
    },

    [buyerGuideReducer.pending]: (state) => {
      state.isLoading = true;
    },
    [buyerGuideReducer.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [buyerGuideReducer.rejected]: (state) => {
      state.isLoading = false;
    },

    [findPropertyReducer.pending]: (state) => {
      state.isLoading = true;
    },
    [findPropertyReducer.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [findPropertyReducer.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default sellerBuyerSlice.reducer;
