import http from "./http";
import url from "../config/server.json";

const baseUrl = url.baseUrl;
export const sellerGuideApi = (data) => {
  const route = `${baseUrl}/sellerGuideConsult`;
  data = {
    name: data.name,
    phone: data.phone,
    email: data.email,
    moreInfo: data.helpType.has("moreInfo")
      ? data.helpType.get("moreInfo")
      : "",
    visit: data.helpType.has("visit") ? data.helpType.get("visit") : "",
    propertyAssessed: data.helpType.has("propertyAssessed")
      ? data.helpType.get("propertyAssessed")
      : "",
    priveteSession: data.helpType.has("priveteSession")
      ? data.helpType.get("priveteSession")
      : "",
    propertyAddress: data.address,
    notes: data.notes,
  };
  return http.post(route, data);
};

export const findHomeValueApi = (data) => {
  const route = `${baseUrl}/findHomevalue`;
  data = {
    propertyAddress: data.address,
    typeOfProperty: data.propertyType,
    yearOfConstruction: data.year,
    numOfBedrooms: data.bedroomsNum,
    numOfBathrooms: data.bathroomsNum,
    outDoorParking: data.outdoorSpaces,
    inDoorParking: data.indoorSpaces,
    whenToSale: data.sellPlan,
    majorRenovationsCompleted: data.renovations,
    name: data.name,
    phone: data.phone,
    email: data.email,
  };
  return http.post(route, data);
};

export const buyerGuideApi = (data) => {
  const route = `${baseUrl}/buyerGuide`;
  data = {
    name: data.name,
    email: data.email,
    phone: data.phone,
    SecteurRecherche: data.secteur,
    TypeOProperty: data.property,
    other: data.other,
    details: data.details,
  };
  return http.post(route, data);
};

export const findPropertyApi = (data) => {
  const route = `${baseUrl}/findProperty`;
  data = {
    fullName: data.name,
    phone: data.phone,
    email: data.email,
    propertyType: data.propertyType,
    bedroomsNeeded: data.bedroomsNeeded,
    bathroomsNeeded: data.bathroomsNeeded,
    budget: data.budget,
    whenToBuy: data.whenToBuy,
    neighbourhood: data.neighbourhood,
    complementaryNotes: data.notes,
  };
  return http.post(route, data);
};

export const getSellerGuideApi = () => {
  const route = `${baseUrl}/sellerGuideConsult`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getBuyerGuideApi = () => {
  const route = `${baseUrl}/buyerGuide`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getHomeValueApi = () => {
  const route = `${baseUrl}/findHomeValue`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getFindPropbertyApi = () => {
  const route = `${baseUrl}/findProperty`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const createBlogApi = (data) => {
  let { published } = data;
  data = data.blog;
  let formData = new FormData();
  formData.append("image", data.file);

  data = {
    subject: JSON.stringify(data.subject),
    body: JSON.stringify(data.counterArray),
    imageUrl: "",
    date: new Date().getTime(),
  };

  formData.append("blog", JSON.stringify(data));

  const route = `${baseUrl}/blog?published=${published}`;
  return http.post(route, formData, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getBlogsApi = (args) => {
  const route = `${baseUrl}/blog?draft=${args}`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getPublishedBlogsApi = () => {
  const route = `${baseUrl}/publishedBlogs`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteBlogApi = (id) => {
  const route = `${baseUrl}/blog/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const editBlogApi = (data) => {
  let { published } = data;
  data = data.blog;
  let formData = new FormData();
  const id = data.id;
  if (data.file) formData.append("image", data.file);

  data = {
    subject: JSON.stringify(data.subject),
    body: JSON.stringify(data.counterArray),
    imageUrl: data.imageUrl,
    date: new Date().getTime(),
  };

  formData.append("blog", JSON.stringify(data));

  const route = `${baseUrl}/blog/${id}?published=${published}`;
  return http.put(route, formData, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const createNeighborhoodsApi = (data) => {
  let { published } = data;
  data = data.blog;
  let formData = new FormData();
  formData.append("image", data.file);

  data = {
    subject: JSON.stringify(data.subject),
    body: JSON.stringify(data.counterArray),
    imageUrl: "",
    date: new Date().getTime(),
  };

  formData.append("neighborhood", JSON.stringify(data));

  const route = `${baseUrl}/neighborhood?published=${published}`;
  return http.post(route, formData, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getNeighborhoodsApi = () => {
  const route = `${baseUrl}/publishedNeighborhood`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getAdminNeighborhoodsApi = (args) => {
  const route = `${baseUrl}/neighborhood?draft=${args}`;
  console.log(route);
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteNeighborhoodApi = (id) => {
  const route = `${baseUrl}/neighborhood/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const editNeighborhoodApi = (data) => {
  let { published } = data;
  data = data.blog;
  let formData = new FormData();
  const id = data.id;
  if (data.file) formData.append("image", data.file);

  data = {
    subject: JSON.stringify(data.subject),
    body: JSON.stringify(data.counterArray),
    imageUrl: data.imageUrl,
    date: new Date().getTime(),
  };

  formData.append("neighborhood", JSON.stringify(data));

  const route = `${baseUrl}/neighborhood/${id}?published=${published}`;
  return http.put(route, formData, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const addListing = (listing) => {
  const route = `${baseUrl}/listings`;
  return http.post(route, listing, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const uploadListingImage = async (images, id) => {
  const route = `${baseUrl}/listingImage`;
  for (let i = 0; i < images.length; i++) {
    let formData = new FormData();
    formData.append("listingId", id);
    formData.append("image", images[i]);
    await http.post(route, formData, {
      headers: { Authorization: localStorage.getItem("token") },
    });
  }
};

export const getListingsApi = () => {
  const route = `${baseUrl}/listings`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteListingsApi = (id) => {
  const route = `${baseUrl}/listings/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const editListing = (listing, id) => {
  const route = `${baseUrl}/listings/${id}`;
  delete listing.id;
  delete listing.gallery;

  for (let element of listing.editedRoomsLevel) {
    let id = element.id;
    delete element.id;
    element.id = id;
  }

  for (let element of listing.editedUnitComposition) {
    let id = element.id;
    delete element.id;
    element.id = id;
  }

  for (let element of listing.newUnitComposition) {
    element.listingId = 0;
  }

  for (let element of listing.newRoomsLevel) {
    element.listingId = 0;
  }

  let editedUnit = [...listing.editedUnitComposition];
  let newUnit = [...listing.newUnitComposition];
  let editedRooms = [...listing.editedRoomsLevel];
  let newRooms = [...listing.newRoomsLevel];

  delete listing.editedUnitComposition;
  delete listing.newUnitComposition;
  delete listing.editedRoomsLevel;
  delete listing.newRoomsLevel;

  listing.newUnitComposition = newUnit;
  listing.editedUnitComposition = editedUnit;
  listing.newRoomsLevel = newRooms;
  listing.editedRoomsLevel = editedRooms;

  return http.put(route, listing, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteListingsImage = (id) => {
  const route = `${baseUrl}/listingImage/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const createLeadApi = (leads) => {
  const route = `${baseUrl}/leads`;
  return http.post(route, leads, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const getLeadsApi = () => {
  const route = `${baseUrl}/leads`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteLeadApi = (id) => {
  const route = `${baseUrl}/leads/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const editLeadApi = (lead) => {
  const route = `${baseUrl}/leads/${lead.id}`;
  delete lead.id;
  return http.put(route, lead, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const sendSmsApi = (message) => {
  const route = `${baseUrl}/leadsSms`;
  return http.post(route, message, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const sendEmailApi = (message) => {
  console.log(message);
  const route = `${baseUrl}/leadsEmail`;
  return http.post(route, message, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const uploadContentImagesApi = async (data) => {
  for (let element of data) {
    let formData = new FormData();
    formData.append("image", element.file);
    formData.append("type", element.type);

    const route = `${baseUrl}/image`;
    await http.post(route, formData, {
      headers: { Authorization: localStorage.getItem("token") },
    });
  }
};

export const getImagesApi = () => {
  const route = `${baseUrl}/image`;
  return http.get(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteSellerGuideApi = (id) => {
  const route = `${baseUrl}/sellerGuideConsult/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteBuyerGuideApi = (id) => {
  const route = `${baseUrl}/buyerGuide/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteFindHomeValueApi = (id) => {
  const route = `${baseUrl}/findHomevalue/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};

export const deleteFindPropertyApi = (id) => {
  const route = `${baseUrl}/findProperty/${id}`;
  return http.delete(route, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};
