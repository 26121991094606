import TestimonialsContainer from "./TestimonialsContainer";
import { useDispatch, useSelector } from "react-redux";

import "../testimonials.css";
import {
  getTestimonialReducer,
  sendTestimonialReducer,
} from "../testimonialSlice";
import { useState } from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import back from "../../../images/home.jpg";

const Tesimonials = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.testimonials.isLoading);
  const testimonials = useSelector((state) => state.testimonials.testimonials);

  const [testimonialData, setTestimonialData] = useState({
    title: "",
    name: "",
    testimonial: "",
  });

  const [errors, setErrors] = useState({
    title: "",
    name: "",
  });

  useEffect(() => {
    dispatch(getTestimonialReducer());
  }, [dispatch]);

  const handleSubmit = () => {
    const errorsObj = { ...errors };
    let isErrors = false;
    for (let element in testimonialData) {
      if (element === "name" || element === "title") {
        if (!testimonialData[element]) {
          errorsObj[element] = "error";
          isErrors = true;
        }
      }
    }

    if (isErrors) toast.error("please fill the required fields");
    setErrors(errorsObj);

    if (!isErrors)
      dispatch(sendTestimonialReducer(testimonialData))
        .unwrap()
        .then(() => {
          toast.success("Your testimonial has been submitted successfully");
          setTestimonialData({
            title: "",
            name: "",
            testimonial: "",
          });
        });
  };

  const handleChange = ({ currentTarget }) => {
    const errorsObj = { ...errors };
    if (errorsObj[currentTarget.name] && currentTarget.value) {
      errorsObj[currentTarget.name] = "";
      setErrors(errorsObj);
    } else if (errorsObj[currentTarget.name] === "" && !currentTarget.value) {
      errorsObj[currentTarget.name] = "error";
      setErrors(errorsObj);
    }
    setTestimonialData((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const formatDate = (date) => {
    date = new Date();
    date.setTime(date);
    return date.toDateString();
  };

  const lang = getLanguageObject().testimonial;

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <TestimonialsContainer
      bg={image.testimonials ? image.testimonials : loadedImages ? back : ""}
    >
      <ToastContainer />
      <>
        {testimonials.map((element, index) => (
          <div className="testimonial" key={index}>
            <h3>{element.title}</h3>
            <p style={{ marginBottom: "0" }}>{element.testimonial}</p>
            <footer>
              {element.name} - {formatDate(element.date)}
            </footer>
          </div>
        ))}

        <div className="testimonial-form">
          <h3>{lang.write}</h3>
          <section className={` ${errors.title ? "error-test" : ""}`}>
            {lang.title} <span>*</span>
            <input
              type="text"
              name="title"
              value={testimonialData.title}
              onChange={handleChange}
            />
          </section>
          <section className={` ${errors.name ? "error-test" : ""}`}>
            {lang.name} <span>*</span>
            <input
              type="text"
              name="name"
              value={testimonialData.name}
              onChange={handleChange}
            />
          </section>
          <section>
            {lang.your}
            <textarea
              name="testimonial"
              rows={10}
              value={testimonialData.testimonial}
              onChange={handleChange}
            ></textarea>
          </section>
          <button onClick={handleSubmit} disabled={isLoading}>
            {isLoading && <div className="main-loader"></div>}
            {!isLoading && (
              <>
                <i className="lar la-paper-plane" />
                {lang.send}
              </>
            )}
          </button>
        </div>
      </>
    </TestimonialsContainer>
  );
};

export default Tesimonials;
