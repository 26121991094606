import { withRouter } from "react-router-dom";

const SingleBlog = (props) => {
  const { element } = props.history.location.state;

  const getDate = (date) => {
    let returnDate = new Date();
    returnDate.setTime(date);
    return returnDate.toDateString();
  };

  const body = JSON.parse(JSON.parse(element.body));

  const getBody = (string) => {
    while (string.indexOf("`") !== -1) {
      const startIndex = string.indexOf("`");

      string = string.replace("`", "");
      const endIndex = string.indexOf("`");
      string = string.replace("`", "");

      string = string.replace(
        string.substring(startIndex, endIndex),
        `<a href=${string.substring(
          startIndex,
          endIndex
        )} rel="noreferrer" target="_blank">${string.substring(
          startIndex,
          endIndex
        )}</a>`
      );
    }
    return string;
  };

  return (
    <div className="single-blog">
      <h4>
        {
          JSON.parse(element.subject)[
            JSON.parse(element.subject)["en"] !== ""
              ? "en"
              : JSON.parse(element.subject)["fr"] !== ""
              ? "fr"
              : "ar"
          ]
        }
      </h4>
      <p>{getDate(element.date)}</p>
      <div
        style={{ backgroundImage: `url(${element.imageUrl})` }}
        className="img"
      ></div>
      {body.map((item, index) => (
        <section key={index}>
          <h4>
            {
              item.header[
                item.header["en"] !== ""
                  ? "en"
                  : item.header["fr"] !== ""
                  ? "fr"
                  : "ar"
              ]
            }
          </h4>
          <pre
            dangerouslySetInnerHTML={{
              __html: getBody(
                item.body[
                  item.body["en"] !== ""
                    ? "en"
                    : item.body["fr"] !== ""
                    ? "fr"
                    : "ar"
                ]
              ),
            }}
          ></pre>
        </section>
      ))}
    </div>
  );
};

export default withRouter(SingleBlog);
