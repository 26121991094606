import "../../admin.css";
import logo from "../../../../images/logo.png";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { useState } from "react";
import Profile from "../profile/Profile";
import SellerGuide from "../seller and buyer/SellerGuide";
import BuyerGuide from "../seller and buyer/BuyerGuide";
import FindHomeValue from "../seller and buyer/FindHomeValue";
import FindProperty from "../seller and buyer/FindProperty";
import Blogs from "../blogs/Blogs";
import SingleBlog from "../blogs/SingleBlog";
import CreateBlog from "../blogs/CreateBlod";
import EditBlog from "../blogs/EditBlog";
import Neighbourhoods from "../neighbourhoods/Neighbourhoods";
import CreateNeighbourhood from "../neighbourhoods/CreateNeighbourhood";
import SingleNeighborhood from "../neighbourhoods/SingleNeighborhood";
import EditNeighborhood from "../neighbourhoods/EditNeighborhood";
import AddListings from "../listings/AddListings";
import AllListings from "../listings/AllListings";
import EditListing from "../listings/EditListing";
import Leads from "../leads/Leads";
import CreateLead from "../leads/CreateLead";
import EditLead from "../leads/EditLead";
import SendMessage from "../leads/SendMessage";
import Content from "../profile/Content";
import Testimonials from "../testimonials/Testimonials";
import { useEffect } from "react";

const Dashboard = () => {
  const [opened, setOpened] = useState(true);

  const [sm, setSm] = useState(false);

  useEffect(() => {
    if (new Date().getTime() - localStorage.getItem("time") > 900000) {
      localStorage.removeItem("token");
      localStorage.removeItem("time");
      window.location = "/admin/login";
    }
    if (localStorage.getItem("lang") !== "en") {
      localStorage.setItem("lang", "en");
      window.location = window.location.pathname;
    }
  }, []);

  return (
    <>
      <nav
        className={`sidebar ${opened ? "open" : "close"} ${
          sm ? "sm-open" : ""
        }`}
      >
        <header>
          <div className="image-text">
            <span className="image">
              <img src={logo} alt="logo" />
            </span>

            <div className="text logo-text">
              <section>
                <span className="name">Joulnar</span>
                <span className="profession">Real Estate Broker</span>
              </section>
            </div>
          </div>
          <i
            className={`las la-angle-${opened ? "left" : "right"} toggle`}
            onClick={() => setOpened(!opened)}
          />
          <i className={`las la-bars sm`} onClick={() => setSm(!sm)} />
        </header>

        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              <li
                className="nav-link"
                onClick={() => setSm(false)}
                style={{ marginTop: "180px" }}
              >
                <Link to="/admin/dashboard/leads">
                  <i className="las la-users-cog icon"></i>
                  <span className="text nav-text">Leads</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/listings">
                  <i className="las la-users icon"></i>
                  <span className="text nav-text">Listings</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/seller-guide">
                  <i className="las la-file-invoice icon" />
                  <span className="text nav-text">Seller's guide</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/home-value">
                  <i className="las la-home icon" />
                  <span className="text nav-text">Home's value</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/buyer-guide">
                  <i className="las la-file-invoice icon" />
                  <span className="text nav-text">Buyer's guide</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/find-property">
                  <i className="las la-home icon" />
                  <span className="text nav-text">Find a property</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/blog">
                  <i className="las la-blog icon" />
                  <span className="text nav-text">Blog</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/testimonials">
                  <i className="las la-file-invoice icon" />
                  <span className="text nav-text">Testimonials</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/neighborhoods">
                  <i className="las la-users icon" />
                  <span className="text nav-text">Neighborhoods</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/content-management">
                  <i className="las la-image icon" />
                  <span className="text nav-text">Content</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/">
                  <i className="las la-home icon" />
                  <span className="text nav-text">Website</span>
                </Link>
              </li>

              <li className="nav-link" onClick={() => setSm(false)}>
                <Link to="/admin/dashboard/profile">
                  <i className="las la-user-cog icon" />
                  <span className="text nav-text">Profile</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom-content" style={{ marginTop: "-15px" }}>
          <li className="" onClick={() => setSm(false)}>
            <Link to="/logout">
              <i className="las la-sign-out-alt icon" />
              <span className="text nav-text">Logout</span>
            </Link>
          </li>
        </div>
      </nav>
      <div className={`dashboard-content ${opened ? "" : "dashboard-stretch"}`}>
        <Switch>
          <Route path="/admin/dashboard/profile" component={Profile}></Route>

          <Route
            path="/admin/dashboard/content-management"
            component={Content}
          ></Route>

          <Route
            path="/admin/dashboard/seller-guide"
            component={SellerGuide}
          ></Route>

          <Route
            path="/admin/dashboard/home-value"
            component={FindHomeValue}
          ></Route>

          <Route
            path="/admin/dashboard/find-property"
            component={FindProperty}
          ></Route>

          <Route
            path="/admin/dashboard/blog/:id"
            component={SingleBlog}
          ></Route>

          <Route
            path="/admin/dashboard/testimonials"
            component={Testimonials}
          ></Route>

          <Route path="/admin/dashboard/blog" component={Blogs}></Route>

          <Route
            path="/admin/dashboard/buyer-guide"
            component={BuyerGuide}
          ></Route>

          <Route
            path="/admin/dashboard/create-blog"
            component={CreateBlog}
          ></Route>

          <Route path="/admin/dashboard/edit-blog" component={EditBlog}></Route>

          <Route
            path="/admin/dashboard/edit-neighborhood"
            component={EditNeighborhood}
          ></Route>

          <Route
            path="/admin/dashboard/neighborhoods/:id"
            component={SingleNeighborhood}
          ></Route>

          <Route
            path="/admin/dashboard/neighborhoods"
            component={Neighbourhoods}
          ></Route>

          <Route
            path="/admin/dashboard/leads/message"
            component={SendMessage}
          ></Route>

          <Route path="/admin/dashboard/leads/:id" component={EditLead}></Route>

          <Route path="/admin/dashboard/leads" component={Leads}></Route>

          <Route
            path="/admin/dashboard/create-lead"
            component={CreateLead}
          ></Route>

          <Route
            path="/admin/dashboard/create-neighborhood"
            component={CreateNeighbourhood}
          ></Route>
          <Route
            path="/admin/dashboard/listing/:id"
            component={EditListing}
          ></Route>
          <Route
            path="/admin/dashboard/listings/add"
            component={AddListings}
          ></Route>
          <Route
            path="/admin/dashboard/listings"
            component={AllListings}
          ></Route>
          <Redirect to="/admin/dashboard/leads" />
        </Switch>
      </div>
    </>
  );
};

export default Dashboard;
