import { withRouter } from "react-router-dom";

const SingleFindProperty = ({ element }) => {
  return (
    <div className="sb-admin no-shadow request-buyer">
      <section className="request-data ">
        <span>Name:</span>
        <span>{element.fullName}</span>
      </section>
      <section className="request-data">
        <span>Email address:</span>
        <span>
          <a href={`mailto:${element.name}`}>{element.email}</a>
        </span>
      </section>
      <section className="request-data">
        <span>Phone number:</span>
        <span>
          <a href={`tel:${element.phone}`}>{element.phone}</a>
        </span>
      </section>

      <section className="request-data">
        <span>Property type:</span>
        <span>{element.propertyType}</span>
      </section>

      <section className="request-data">
        <span>Bedrooms needed:</span>
        <span>{element.bedroomsNeeded}</span>
      </section>

      <section className="request-data">
        <span>Bathrooms needed:</span>
        <span>{element.bathroomsNeeded}</span>
      </section>

      <section className="request-data">
        <span>Budget:</span>
        <span>{element.budget}</span>
      </section>

      <section className="request-data">
        <span>Plan to buy:</span>
        <span>{element.whenToBuy}</span>
      </section>

      <section className="request-data">
        <span>Neighbourhoods wanted:</span>
        <span>{element.neighbourhood}</span>
      </section>

      {element.complementaryNotes && (
        <section className="request-data">
          <span>Complementary notes:</span>
          <span>{element.complementaryNotes}</span>
        </section>
      )}
    </div>
  );
};

export default withRouter(SingleFindProperty);
