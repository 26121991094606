import "../listings.css";

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useState } from "react";
import ClusterMap from "../../../common/components/ClusterMap";
import { useEffect } from "react";
import { getListingsApi } from "../../../api/sellerBuyerApi";
import { useSelector } from "react-redux";
import back from "../../../images/listing.jpeg";

const Listings = () => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const image = useSelector((state) => state.admin.images);

  const handleChange = ({ currentTarget }) => {
    setSearch(currentTarget.value);
  };

  useEffect(() => {
    const getData = async () => {
      let { data } = await getListingsApi();
      setList(data.data);
    };

    getData();
  }, []);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <>
      <div
        className="upper-bg"
        style={{
          backgroundImage: `url(${
            image.listings ? image.listings : loadedImages ? back : ""
          })`,
          backgroundPosition: "center",
          height: "500px",
        }}
      ></div>
      <div className="listings">
        <input
          type="search"
          name="search"
          placeholder="Search using title.."
          value={search}
          onChange={handleChange}
        />
        {list
          .filter((listing) =>
            listing.listingName.toLowerCase().includes(search.toLowerCase())
          )
          .map((listing, index) => (
            <Container
              fluid
              className="listing"
              key={index}
              style={{ overflow: "hidden", margin: "2rem auto" }}
            >
              <Row className="justify-content-center">
                <Col md={4} sm={8} xs={12} className="contain">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${listing.gallery[0]?.url})`,
                    }}
                  ></div>
                  <section>
                    <span>{listing.type}</span>
                    <span>{listing.listingType}</span>
                  </section>
                </Col>
                <Col
                  md={8}
                  sm={8}
                  xs={12}
                  className="content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Link to={`/listings/${listing.id}`}>
                    {listing.listingName}
                  </Link>
                  <p>{listing.address}</p>
                  <p>{listing.units} units</p>
                  <p className="description-content">
                    {listing.descriptionContent}
                  </p>
                  <h3>${listing.price}</h3>
                </Col>
              </Row>
            </Container>
          ))}
      </div>
      <div className="cluster-map" style={{ marginBottom: "-3rem" }}>
        <ClusterMap data={list} />
      </div>
    </>
  );
};

export default Listings;
