import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { editLeadReducer } from "../../adminSlice";
import { withRouter } from "react-router-dom";

const EditBlog = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.blodLoading);
  const item = props.history.location.state.element;

  const [blog, setBlog] = useState({
    id: item.id,
    name: item.name,
    email: item.email,
    phone: item.phone,
    description: item.description,
  });

  const handleChange = ({ currentTarget }) => {
    setBlog((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = () => {
    dispatch(editLeadReducer(blog))
      .unwrap()
      .then((_) => {
        toast.success("The lead has been edited successfully");
      });
  };

  return (
    <div className="single-blog create-blog">
      <ToastContainer />
      <h4>Edit the lead</h4>
      <input
        type="text"
        name="name"
        value={blog.name}
        placeholder="Lead Name"
        onChange={handleChange}
      />
      <input
        type="text"
        name="email"
        value={blog.email}
        placeholder="Lead Email"
        onChange={handleChange}
      />
      <input
        type="text"
        name="phone"
        value={blog.phone}
        placeholder="Lead Phone"
        onChange={handleChange}
      />
      <textarea
        placeholder="Description"
        rows={10}
        name="description"
        onChange={handleChange}
        style={{ marginTop: "1rem" }}
        value={blog.description}
      ></textarea>

      <div>
        <button onClick={handleSubmit} disabled={isLoading} className="save">
          {isLoading && <div className="main-loader"></div>}
          {!isLoading && <>Send</>}
        </button>
      </div>
    </div>
  );
};

export default withRouter(EditBlog);
