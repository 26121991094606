const FifthPage = ({ listing, handleChange }) => {
  return (
    <div className="fifth-page">
      <h5>Taxes</h5>
      <section>
        <label>Municipal taxes</label>
        <input
          type="text"
          name="municipalTaxes"
          value={listing.municipalTaxes}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>School Taxes</label>
        <input
          type="text"
          name="schoolTaxes"
          value={listing.schoolTaxes}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Total</label>
        <input
          type="text"
          name="totalTaxes"
          value={listing.totalTaxes}
          disabled
        />
      </section>
    </div>
  );
};

export default FifthPage;
