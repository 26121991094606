import BlogsContainer from "../../blogs/components/BlogsContainer";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import back from "../../../images/home.jpg";

const SingleNeighborhood = (props) => {
  const { element } = props.history.location.state;

  const getDate = (date) => {
    let returnDate = new Date();
    returnDate.setTime(date);
    return returnDate.toDateString();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (
      JSON.parse(JSON.parse(element.body))[0].body[
        localStorage.getItem("lang")
      ] === ""
    ) {
      window.location = "/";
    }
  }, [element.body]);

  const body = JSON.parse(JSON.parse(element.body));

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  const getBody = (string) => {
    while (string.indexOf("`") !== -1) {
      const startIndex = string.indexOf("`");

      string = string.replace("`", "");
      const endIndex = string.indexOf("`");
      string = string.replace("`", "");

      string = string.replace(
        string.substring(startIndex, endIndex),
        `<a href=${string.substring(
          startIndex,
          endIndex
        )} rel="noreferrer" target="_blank">${string.substring(
          startIndex,
          endIndex
        )}</a>`
      );
    }
    return string;
  };

  return (
    <BlogsContainer
      bg={image.neighborhoods ? image.neighborhoods : loadedImages ? back : ""}
    >
      <div
        className="single-blog single-home-blog"
        style={{
          borderRadius: "0.125rem",
          marginTop: 0,
          paddingBottom: "2rem",
        }}
      >
        <h4>{JSON.parse(element.subject)[localStorage.getItem("lang")]}</h4>
        <p>{getDate(element.date)}</p>
        <div
          style={{ backgroundImage: `url(${element.imageUrl})` }}
          className="img"
        ></div>
        {body.map((item, index) => (
          <section key={index}>
            <h4
              style={{
                direction:
                  localStorage.getItem("lang") === "ar" ? "rtl" : "ltr",
              }}
            >
              {item.header[localStorage.getItem("lang")]}
            </h4>
            <pre
              style={{
                direction:
                  localStorage.getItem("lang") === "ar" ? "rtl" : "ltr",
              }}
              dangerouslySetInnerHTML={{
                __html: getBody(item.body[localStorage.getItem("lang")]),
              }}
            ></pre>
          </section>
        ))}
      </div>
    </BlogsContainer>
  );
};

export default withRouter(SingleNeighborhood);
