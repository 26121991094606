import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFzZWwtYmVzaGFycCIsImEiOiJjbGMzbmNmNXcwbDBoM3Zuem1teXhldmtpIn0.wtJ8ZWz84bXkMLMgXg9I8w";

const ClusterMap = ({ data }) => {
  const [viewport, setViewport] = useState({
    latitude: "",
    longitude: "",
    zoom: 6,
  });

  const [loaded, setLoaded] = useState(false);

  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (data.length) {
      setLoaded(true);
      setViewport({
        latitude: +data[0].latitude,
        longitude: +data[0].longitude,
        zoom: 6,
      });
      let marks = data.map((element) => {
        return {
          longitude: +element.longitude,
          latitude: +element.latitude,
        };
      });
      setMarkers(marks);
    }
  }, [data]);

  return (
    <div style={{ height: "75vh" }} className="cluster">
      {loaded && (
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v12"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          scrollZoom={false}
        >
          {markers.map((marker, index) => (
            <Marker
              longitude={marker.longitude}
              latitude={marker.latitude}
              key={index}
            >
              <div className="marker temporary-marker">
                <span></span>
              </div>
            </Marker>
          ))}
          <NavigationControl style={{ position: "absolute", top: 0 }} />
        </MapGL>
      )}
    </div>
  );
};
export default ClusterMap;
