import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import CommonModal from "../../../common/components/CommonModal";
import logo from "../../../images/logo.png";
import via from "../../../images/via.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getNeighborhoodsReducer } from "../../admin/adminSlice";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { emailSenderApi } from "../../../api/emailApi";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import profile from "../../../images/profile.jpg";
import { ToastContainer, toast } from "react-toastify";
import besharp from "../../../images/besharp logo.png";

const Footer = (props) => {
  const langCard = getLanguageObject().card;
  const blogs = useSelector((state) => state.admin.neigs);
  const dispatch = useDispatch();
  const [email, setEmail] = useState({
    name: "",
    email: "",
    phone: "",
    message: langCard.hello,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getNeighborhoodsReducer());
  }, [dispatch]);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await emailSenderApi(email);
      setIsLoading(false);
      setEmail({
        name: "",
        email: "",
        phone: "",
        message: langCard.hello,
      });
      toast.success("Your email has been sent successfully");
    } catch (err) {
      setIsLoading(false);
      toast.error("Error!, try again please");
    }
  };

  const handleChange = ({ currentTarget }) => {
    setEmail((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const lang = getLanguageObject().header;
  const langFooter = getLanguageObject().footer;

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <footer className="main">
      <ToastContainer />
      <Container fluid className="container">
        <Row className="justify-content-center">
          <Col lg={3} sm={6} xs={12} className="first">
            <span style={{ color: "rgb(227, 43, 54)" }}>
              Joulnar El Husseini-McCormick
            </span>
            <Link to="/home">{lang.home}</Link>
            <Link to="/about">{lang.about}</Link>
            <Link to="/listings">{lang.listings}</Link>

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {lang.sell}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div">
                  <Link to="/seller-guide">{lang.sellerGuide}</Link>
                </Dropdown.Item>
                <Dropdown.Item as="div">
                  <Link to="/home-value">{lang.findHomeValue}</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {lang.buy}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div">
                  <Link to="/buyer-guide">{lang.buyerGuide}</Link>
                </Dropdown.Item>
                <Dropdown.Item as="div">
                  <Link to="/find-property">{lang.findProperty}</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Link to="/testimonials">{lang.testimonials}</Link>
            <Link to="/blogs">{lang.blogs}</Link>
            <Link to="/contact">{lang.contact}</Link>

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {lang.neigborhoods}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {blogs
                  .filter(
                    (item) =>
                      JSON.parse(JSON.parse(item.body))[0].body[
                        localStorage.getItem("lang")
                      ]
                  )
                  .map((element, index) => (
                    <Dropdown.Item
                      as="div"
                      key={index}
                      onClick={() => {
                        props.history.push({
                          pathname: "/neighborhoods/" + element.id,
                          state: { element },
                        });
                      }}
                    >
                      <Link to="#">
                        {" "}
                        {
                          JSON.parse(element.subject)[
                            localStorage.getItem("lang")
                          ]
                        }
                      </Link>
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col lg={3} sm={6} xs={12} className="second">
            <span>{langFooter.toReach}</span>
            <a
              href="https://www.suttonquebec.com/fr/courtier-immobilier/60993-joulnar-el-husseini-mccormick-groupe-sutton-immobilia-inc-.html"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#E84042",
                fontWeight: "600",
                textDecoration: "none",
              }}
            >
              {" "}
              <span>{langFooter.via}</span>
            </a>
            <section className="phone">
              <a href="tel:514 970-3704">
                <i
                  className="las la-mobile"
                  style={{ color: "rgb(227, 43, 54)" }}
                />
                <span style={{ color: "rgb(227, 43, 54)" }}>514 970-3704</span>
              </a>
            </section>
            <section className="email">
              <CommonModal
                heading={langCard.contact}
                handleclick={handleClick}
                isloading={isLoading}
              >
                <p>
                  <i className="las la-envelope" /> {langCard.send}
                </p>
                <div className="email-body">
                  <input
                    type="text"
                    name="name"
                    placeholder={langCard.name}
                    value={email.name}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder={langCard.email}
                    name="email"
                    value={email.email}
                    onChange={handleChange}
                  />
                  <input
                    type="phone"
                    placeholder={langCard.phone}
                    name="phone"
                    value={email.phone}
                    onChange={handleChange}
                  />
                  <textarea
                    rows={10}
                    name="message"
                    value={email.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </CommonModal>
            </section>
            <section className="social">
              <a href={window.location.pathname}>
                <i className="las la-globe-europe" />
              </a>
              <a href="https://www.facebook.com/people/Joulnar-Mccormick-Immobilier/100063695612255/">
                <i className="lab la-facebook" />
              </a>
              <a href="https://www.linkedin.com/in/joulnar-mccormick-96a72917/">
                <i className="lab la-linkedin" />
              </a>
              <a href="https://www.instagram.com/joulnar_immobilier/">
                <i className="lab la-instagram" />
              </a>
            </section>
          </Col>
          <Col lg={3} sm={6} xs={12} className="third">
            {loadedImages && (
              <img
                src={image.profile ? image.profile : profile}
                alt="profile"
              />
            )}
            <img src={logo} alt="logo" />
          </Col>
          <Col lg={3} sm={6} xs={12} className="fourth">
            <span>{langFooter.follow}</span>
            <a
              href="https://www.facebook.com/people/Joulnar-Mccormick-Immobilier/100063695612255/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="lab la-facebook" />
              <span>Joulnar Mccormick</span>
            </a>
            <section style={{ display: "block" }}>
              {langFooter.network}
              {"  "}
              <a
                href="https://www.suttonquebec.com/fr/courtier-immobilier/60993-joulnar-el-husseini-mccormick-groupe-sutton-immobilia-inc-.html"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#E84042" }}
              >
                Groupe Sutton - immobilia.inc
              </a>
            </section>
            <a
              href="https://www.suttonquebec.com/fr/courtier-immobilier/60993-joulnar-el-husseini-mccormick-groupe-sutton-immobilia-inc-.html"
              target="_blank"
              rel="noreferrer"
            >
              <img src={via} alt="via capitale" style={{ width: "160px" }} />
            </a>
          </Col>
        </Row>
      </Container>
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {" "}
        {langFooter.developed}{" "}
        <a
          href="https://www.besharp.dev/"
          rel="noreferrer"
          target="_blank"
          style={{ marginTop: "-14px" }}
        >
          <img
            src={besharp}
            style={{ width: "65px", margin: "0 0.3rem", marginTop: "10px" }}
            alt="besharp logo"
          />
        </a>
        &copy; {new Date().getFullYear()} - {langFooter.rights}
      </p>
    </footer>
  );
};

export default withRouter(Footer);
