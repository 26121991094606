import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../../images/logo.png";
import { Link } from "react-router-dom";
import fr from "../../../images/fr.jpg";
import uk from "../../../images/uk.png";
import ksa from "../../../images/ksa.jpg";

import "../structure.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getImagesReducer,
  getNeighborhoodsReducer,
} from "../../admin/adminSlice";
import { withRouter } from "react-router-dom";
import { getLanguageObject } from "../../../common/utils/languageDetector";

const Header = (props) => {
  const blogs = useSelector((state) => state.admin.neigs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNeighborhoodsReducer());
    dispatch(getImagesReducer());
  }, [dispatch]);

  const { header } = getLanguageObject();
  return (
    <div className="header">
      <Navbar collapseOnSelect expand="lg">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/home" style={{ padding: 0 }}>
              <img src={logo} alt="Joulnar's logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#" as="div">
                <Link to="/home" id="RouterNavLink">
                  {header.home}
                </Link>
              </Nav.Link>
              <Nav.Link href="#" as="div">
                <Link to="/about" id="RouterNavLink">
                  {header.about}
                </Link>
              </Nav.Link>
              <Nav.Link href="#" as="div">
                <Link to="/listings" id="RouterNavLink">
                  {header.listings}
                </Link>
              </Nav.Link>

              <NavDropdown
                title={header.sell}
                id="collasible-nav-dropdown-sell"
              >
                <NavDropdown.Item href="#" as="div">
                  <Link to="/seller-guide"> {header.sellerGuide}</Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#" as="div">
                  <Link to="/home-value"> {header.findHomeValue}</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={header.buy} id="collasible-nav-dropdown-but">
                <NavDropdown.Item href="#" as="div">
                  <Link to="/buyer-guide"> {header.buyerGuide}</Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#" as="div">
                  <Link to="/find-property">{header.findProperty}</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="#" as="div">
                <Link to="/testimonials" id="RouterNavLink">
                  {header.testimonials}
                </Link>
              </Nav.Link>
              <Nav.Link href="#" as="div">
                <Link to="/blogs" id="RouterNavLink">
                  {header.blogs}
                </Link>
              </Nav.Link>
              <Nav.Link href="#" as="div">
                <Link to="/contact" id="RouterNavLink">
                  {header.contact}
                </Link>
              </Nav.Link>

              <NavDropdown
                title={header.neigborhoods}
                id="collasible-nav-dropdown-neighbor"
              >
                {blogs
                  ?.filter(
                    (item) =>
                      JSON.parse(JSON.parse(item.body))[0].body[
                        localStorage.getItem("lang")
                      ]
                  )
                  .map((element, index) => (
                    <NavDropdown.Item
                      href="#"
                      key={index}
                      className="nei"
                      style={{ padding: "0.7rem !important" }}
                      onClick={() => {
                        props.history.push({
                          pathname: "/neighborhoods/" + element.id,
                          state: { element },
                        });
                      }}
                    >
                      {
                        JSON.parse(element.subject)[
                          localStorage.getItem("lang")
                        ]
                      }
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>

              <NavDropdown
                title={localStorage.getItem("lang").toUpperCase()}
                id="collasible-nav-dropdown-lang"
                className="lang"
              >
                <NavDropdown.Item
                  href="#"
                  className="nei nei-img"
                  style={{ padding: "0.7rem !important" }}
                  onClick={() => {
                    localStorage.setItem("lang", "fr");
                    window.location = window.location.pathname;
                  }}
                >
                  <img src={fr} alt="france" />
                  {header.francais}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  className="nei nei-img"
                  style={{ padding: "0.7rem !important" }}
                  onClick={() => {
                    localStorage.setItem("lang", "en");
                    window.location = window.location.pathname;
                  }}
                >
                  <img src={uk} alt="uk" />
                  {header.english}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  className="nei nei-img"
                  style={{ padding: "0.7rem !important" }}
                  onClick={() => {
                    localStorage.setItem("lang", "ar");
                    window.location = window.location.pathname;
                  }}
                >
                  <img src={ksa} alt="ksa" />
                  {header.arabic}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default withRouter(Header);
