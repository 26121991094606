import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { deleteListingsImage } from "../../../../api/sellerBuyerApi";
import GeoCodingMap from "../../../../common/components/GeoCodingMap";

const EighthPage = ({
  handleChange,
  deleteImageIndex,
  gallery,
  handleMapChange,
  edit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const handleDelete = async (id, index) => {
    try {
      setDeleteIndex(index);
      setIsLoading(true);
      await deleteListingsImage(id);
      deleteImageIndex(id);
    } catch (err) {
      setDeleteIndex(-1);
      setIsLoading(false);
      toast.error("Error, try again");
    }
  };

  return (
    <div className="eighth-page">
      <ToastContainer />
      <h5>Gallery</h5>
      <section style={{ marginBottom: "1.2rem" }}>
        <label>Add new listing images</label>
        <section>
          <div
            className="file-upload-wrapper"
            data-text={
              gallery?.length === 0
                ? "Select your images"
                : gallery.length + " files"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              multiple
            />
          </div>
        </section>
      </section>
      {!edit && (
        <div className="eight-map" style={{ height: "100px !important" }}>
          <GeoCodingMap defaultValue="" handleChange={handleMapChange} />
        </div>
      )}
      {gallery &&
        edit &&
        gallery.map((element, index) => (
          <div
            className="image-container"
            style={{ backgroundImage: `url(${element.url})` }}
            key={index}
          >
            {(!isLoading || (isLoading && deleteIndex !== index)) && (
              <i
                className="las la-trash-alt"
                onClick={() => handleDelete(element.id, index)}
              />
            )}
            {isLoading && deleteIndex === index && (
              <div className="loader-container">
                <div className="main-loader"></div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default EighthPage;
