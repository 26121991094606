const SingleFindHomeValue = ({ element }) => {
  return (
    <div className="sb-admin no-shadow">
      <section className="request-data">
        <span>Name:</span>
        <span>{element.name}</span>
      </section>
      <section className="request-data">
        <span>Email address:</span>
        <span>
          <a href={`mailto:${element.name}`}>{element.email}</a>
        </span>
      </section>
      <section className="request-data">
        <span>Phone number:</span>
        <span>
          <a href={`tel:${element.phone}`}>{element.phone}</a>
        </span>
      </section>
      {element.address && (
        <section className="request-data">
          <span>Property address:</span>
          <span>{element.address}</span>
        </section>
      )}
      <section className="request-data">
        <span>Type of property:</span>
        <span>{element.typeOfProbery}</span>
      </section>

      {element.yearOfConstruction && (
        <section className="request-data">
          <span>Year of construction:</span>
          <span>{element.yearOfConstruction}</span>
        </section>
      )}

      {element.numberOfBedrooms && (
        <section className="request-data">
          <span>Number of bedrooms:</span>
          <span>{element.numberOfBedrooms}</span>
        </section>
      )}

      {element.numberOfBathrooms && (
        <section className="request-data">
          <span>Number of bathrooms:</span>
          <span>{element.numberOfBathrooms}</span>
        </section>
      )}

      {element.outdoorParkingSpaces !== "" && (
        <section className="request-data">
          <span>Outdoor parking spaces:</span>
          <span>{element.outdoorParkingSpaces}</span>
        </section>
      )}

      {element.indoorParkingSpacesOrGarages !== "" && (
        <section className="request-data">
          <span>Indoor parking spaces or garages:</span>
          <span>{element.indoorParkingSpacesOrGarages}</span>
        </section>
      )}

      {element.MajorRenovations !== "" && (
        <section className="request-data">
          <span>Major renovations completed:</span>
          <span>{element.MajorRenovations}</span>
        </section>
      )}

      <section className="request-data">
        <span>Plan to sell:</span>
        <span>{element.whenToSale}</span>
      </section>
    </div>
  );
};

export default SingleFindHomeValue;
