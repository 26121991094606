import React from "react";
import ReactDOM from "react-dom/client";

import store from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import CommonApp from "./app/CommonApp";

import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-slideshow-image/dist/styles.css";

import ".";

if (localStorage.getItem("lang") === "ar")
  import("./localization/styles/rtl.css");
else import("./localization/styles/ltr.css");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CommonApp />
    </BrowserRouter>
  </Provider>
);
