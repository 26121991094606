import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getApprovedTestimonialApi,
  sendTestimonialApi,
} from "../../api/testimonialsApi";

export const sendTestimonialReducer = createAsyncThunk(
  "testimonial/sendTestimonial",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await sendTestimonialApi(args);
      return;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getTestimonialReducer = createAsyncThunk(
  "testimonial/getTestimonial",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getApprovedTestimonialApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

const testimonialSlice = createSlice({
  name: "testimonials",
  initialState: {
    isLoading: false,
    testimonials: [],
  },
  extraReducers: {
    [sendTestimonialReducer.pending]: (state) => {
      state.isLoading = true;
    },
    [sendTestimonialReducer.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [sendTestimonialReducer.rejected]: (state) => {
      state.isLoading = false;
    },

    [getTestimonialReducer.pending]: (state) => {
      state.isLoading = true;
    },
    [getTestimonialReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.testimonials = action.payload;
    },
    [getTestimonialReducer.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default testimonialSlice.reducer;
