import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JoulnarCard from "../../../common/components/JoulnarCard";
import bg from "../../../images/about.jpg";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import { useSelector } from "react-redux";

const About = () => {
  const lang = getLanguageObject().about;
  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <>
      <div
        className="upper-bg"
        style={{
          backgroundImage: `url(${
            image.about ? image.about : loadedImages ? bg : ""
          })`,
        }}
      ></div>
      <Container fluid className="bs-container">
        <Row className="justify-content-center ">
          <Col
            md={7}
            sm={9}
            xs={11}
            className="bs-body"
            style={{
              backgroundColor: "#fff",
              padding: 0,
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,0.125)",
            }}
          >
            <div className="email-body" style={{ padding: "1.25rem" }}>
              <h3 style={{ color: "#e84042", marginBottom: "0.3rem" }}>
                {lang.about}
              </h3>
              <h4 style={{ color: "#e84042", marginBottom: "1rem" }}>
                Joulnar El Husseini-McCormick
              </h4>
              <p
                style={{
                  fontStyle: "italic",
                  fontSize: "1.2rem",
                  marginBottom: "0.5rem",
                }}
              >
                {lang.notOnly}
              </p>
              <p style={{ fontSize: "1.1rem" }}>{lang.aboutPar}</p>
            </div>
          </Col>
          <Col md={3} sm={9} xs={11}>
            <JoulnarCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
