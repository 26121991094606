import ListingsContainer from "./ListingsContainer";
import { Fade } from "react-slideshow-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { getListingsApi } from "../../../api/sellerBuyerApi";
import { useState } from "react";
import { getLanguageObject } from "../../../common/utils/languageDetector";

const SpecificListing = (props) => {
  const [listing, setList] = useState({});

  useEffect(() => {
    const getData = async () => {
      let { data } = await getListingsApi();
      let list = data.data.find(
        (element) => element.id === parseInt(props.match.params.id)
      );
      setList(list);
    };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    getData();
  }, [props.match.params.id]);

  const lang = getLanguageObject().listing;
  const lan = getLanguageObject().specificListing;

  return (
    <ListingsContainer
      bg={listing.gallery ? listing.gallery[0]?.url : ""}
      listing={listing}
    >
      <p className="header-listing" style={{ direction: "ltr" }}>
        <span>{listing.type}</span>
        <span>
          <i className="las la-vector-square" />
          {listing.area} m²
        </span>
        <span>
          <i className="las la-home" />
          {listing.rooms} {lan.rooms}
        </span>
        <span>
          <i className="las la-procedures" />
          {listing.bedrooms} {lan.bedrooms}
        </span>
        <span>
          <i className="las la-bath" />
          {listing.bathrooms} {lan.bathrooms}
        </span>
      </p>
      <div className="listing-body">
        <h3>{listing.address}</h3>
        <p>
          {listing.descriptionContent}
          <h6>{lan.inclusion}</h6>
          {listing.inclusion}
          <h6>{lan.exclusions}</h6>
          {listing.exclusion}
        </p>

        <div className="slide-container">
          <Fade>
            {listing.gallery?.map((fadeImage, index) => (
              <div className="each-fade" key={index}>
                <div className="image-container">
                  <img src={fadeImage.url} alt="listing" />
                </div>
                <h2>{fadeImage.caption}</h2>
              </div>
            ))}
          </Fade>
        </div>

        <div className="tables">
          <p>{lang.general}</p>

          <Container fluid>
            <Row className="tables-row justify-content-between">
              <Col md={6}>
                <div className="table-container-left">
                  <table style={{ direction: "ltr" }}>
                    <tr>
                      <th>{lan.forSale}</th>
                      <td>{listing.listingType}</td>
                    </tr>
                    <tr>
                      <th>{lan.type}</th>
                      <td>{listing.type}</td>
                    </tr>
                    <tr>
                      <th>{lan.price}</th>
                      <td>${listing.price}</td>
                    </tr>
                    <tr>
                      <th>{lan.reference}</th>
                      <td>{listing.reference}</td>
                    </tr>
                    <tr>
                      <th>{lan.units}</th>
                      <td>{listing.units}</td>
                    </tr>
                    <tr>
                      <th>{lan.yearOf}</th>
                      <td>{listing.yearOfConstruction}</td>
                    </tr>
                    <tr>
                      <th>{lan.municipalTax}</th>
                      <td>{listing.municipalTax}</td>
                    </tr>
                    <tr>
                      <th>{lan.schoolTax}</th>
                      <td>{listing.schoolTax}</td>
                    </tr>
                  </table>
                </div>
              </Col>
              <Col md={6}>
                <div className="table-container-right">
                  <table style={{ direction: "ltr" }}>
                    <tr>
                      <th>{lan.rooms}</th>
                      <td>{listing.rooms}</td>
                    </tr>
                    <tr>
                      <th>{lan.bedrooms}</th>
                      <td>{listing.bedrooms}</td>
                    </tr>
                    <tr>
                      <th>{lan.bathrooms}</th>
                      <td>{listing.bathrooms}</td>
                    </tr>
                    <tr>
                      <th>{lan.powderRoom}</th>
                      <td>{listing.powderRooms}</td>
                    </tr>
                    <tr>
                      <th>{lan.liveable}</th>
                      <td>
                        {listing.area} m<sup>2</sup>
                      </td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            <Row className="tables-row justify-content-between">
              <Col md={6}>
                <div className="table-container-left">
                  <table style={{ direction: "ltr" }}>
                    <tr>
                      <th>{lan.sewege}</th>
                      <td>{listing.sewageSystem}</td>
                    </tr>
                    <tr>
                      <th>{lan.water}</th>
                      <td>{listing.waterSupply}</td>
                    </tr>
                    <tr>
                      <th>{lan.roofing}</th>
                      <td>{listing.roofing}</td>
                    </tr>
                    <tr>
                      <th>{lan.heatingEnergy}</th>
                      <td>{listing.heatingEnergy}</td>
                    </tr>
                    <tr>
                      <th>{lan.heatingSystem}</th>
                      <td>{listing.heatingSystem}</td>
                    </tr>
                    <tr>
                      <th>{lan.bathroomWashrooms}</th>
                      <td>{listing.bathroomWashroom}</td>
                    </tr>
                    <tr>
                      <th>{lan.hearthStove}</th>
                      <td>{listing.hearthStove}</td>
                    </tr>
                    <tr>
                      <th>{lan.equipment}</th>
                      <td>{listing.equipmentAvailable}</td>
                    </tr>
                    <tr>
                      <th>{lan.rental}</th>
                      <td>{listing.rentalAppliance}</td>
                    </tr>
                    <tr>
                      <th>{lan.parking}</th>
                      <td>{listing.parking}</td>
                    </tr>
                    <tr>
                      <th>{lan.view}</th>
                      <td>{listing.view}</td>
                    </tr>
                    <tr>
                      <th>{lan.proximity}</th>
                      <td>{listing.proximity}</td>
                    </tr>
                  </table>
                </div>
              </Col>
              <Col md={6}>
                <div className="table-container-right">
                  <table style={{ direction: "ltr" }}>
                    <tr>
                      <th>{lan.typeOfProperty}</th>
                      <td>{listing.typeOfProperty}</td>
                    </tr>
                    <tr>
                      <th>{lan.typeOfBuilding}</th>
                      <td>{listing.typeOfBuilding}</td>
                    </tr>
                    <tr>
                      <th>{lan.buildingDimensions}</th>
                      <td>{listing.buildingDimensions}</td>
                    </tr>
                    <tr>
                      <th>{lan.occupation}</th>
                      <td>{listing.occupationPeriod}</td>
                    </tr>
                  </table>
                  <p>{lan.municipalEval}</p>
                  <table style={{ direction: "ltr" }}>
                    <tr>
                      <th>{lan.year}</th>
                      <td>{listing.year}</td>
                    </tr>
                    <tr>
                      <th>{lan.land}</th>
                      <td>{listing.landEvaluation}</td>
                    </tr>
                    <tr>
                      <th>{lan.buildingEval}</th>
                      <td>{listing.buildingEvaluation}</td>
                    </tr>
                    <tr>
                      <th>{lan.total}</th>
                      <td>{listing.total}</td>
                    </tr>
                  </table>
                  <p>{lan.taxes}</p>
                  <table style={{ direction: "ltr" }}>
                    <tr>
                      <th>{lan.municipalTaxes}</th>
                      <td>{listing.municipalTaxes}</td>
                    </tr>
                    <tr>
                      <th>{lan.schoolTax}</th>
                      <td>{listing.schoolTaxes}</td>
                    </tr>
                    <tr>
                      <th>{lan.total}</th>
                      <td>{listing.totalTaxes}</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            <p>{lang.composition}</p>
            <div className="table-container">
              <table className="whole-row" style={{ direction: "ltr" }}>
                <tr>
                  <th>{lan.unitType}</th>
                  <th>{lan.total}</th>
                  <th>{lan.totalVacant}</th>
                  <th>{lan.surface}</th>
                  <th>{lan.description}</th>
                </tr>
                {listing.unitComposition?.map((element, index) => (
                  <tr key={index}>
                    <td className="left-imp">{element.unitType}</td>
                    <td className="left-imp">{element.total}</td>
                    <td className="left-imp">{element.totalVacant}</td>
                    <td className="left-imp">{element.surface}</td>
                    <td className="left-imp">{element.description}</td>
                  </tr>
                ))}
              </table>
            </div>

            <p>{lang.rooms}</p>
            <div className="table-container">
              <table className="whole-row" style={{ direction: "ltr" }}>
                <tr>
                  <th>{lan.level}</th>
                  <th>{lan.name}</th>
                  <th>{lan.dimensions}</th>
                  <th>{lan.flooring}</th>
                  <th>{lan.information}</th>
                </tr>
                {listing.roomsLevel?.map((element, index) => (
                  <tr key={index}>
                    <td className="left-imp">{element.level}</td>
                    <td className="left-imp">{element.name}</td>
                    <td className="left-imp">{element.dimensions}</td>
                    <td className="left-imp">{element.flooring}</td>
                    <td className="left-imp">{element.informations}</td>
                  </tr>
                ))}
              </table>
            </div>
          </Container>
        </div>
      </div>
    </ListingsContainer>
  );
};

export default withRouter(SpecificListing);
