import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { getLanguageObject } from "../utils/languageDetector";

const MyVerticallyCenteredModal = (props) => {
  const lang = getLanguageObject().card;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button
          className="final"
          onClick={props.handleclick}
          disabled={props.isloading}
        >
          {!props.isloading && (
            <>
              {lang.submit} <i className="lar la-paper-plane" />
            </>
          )}
          {props.isloading && <div className="main-loader"></div>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CommonModal = ({ heading, children, handleclick, isloading }) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setModalShow(true)}
        className="modal-button"
        style={{ height: "38px " }}
      >
        {children[0]}
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        heading={heading}
        handleclick={handleclick}
        isloading={isloading}
      >
        {children[1]}
      </MyVerticallyCenteredModal>
    </>
  );
};

export default CommonModal;
