const FourthPage = ({ listing, handleChange }) => {
  return (
    <div className="fourth-page">
      <h5>Municipal evaluation</h5>
      <section>
        <label>Year</label>
        <input
          type="text"
          name="year"
          value={listing.year}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Land Evaluation</label>
        <input
          type="text"
          name="landEvaluation"
          value={listing.landEvaluation}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Building Evaluation</label>
        <input
          type="text"
          name="buildingEvaluation"
          value={listing.buildingEvaluation}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Total</label>
        <input type="text" name="total" value={listing.total} disabled />
      </section>
    </div>
  );
};

export default FourthPage;
