import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../seller and buyer/seller.css";

const HomeContainer = ({ children, bg, bgcolor }) => {
  return (
    <>
      <div className="upper-bg" style={{ backgroundImage: `url(${bg})` }}></div>
      <Container fluid className="bs-container">
        <Row className="justify-content-center ">
          <Col
            md={11}
            sm={11}
            xs={11}
            className="bs-body"
            style={{ background: bgcolor }}
          >
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeContainer;
