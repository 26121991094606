import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFzZWwtYmVzaGFycCIsImEiOiJjbGMzbmNmNXcwbDBoM3Zuem1teXhldmtpIn0.wtJ8ZWz84bXkMLMgXg9I8w";

const GeoCodingMap = ({ handleChange, defaultValue }) => {
  const [viewport, setViewport] = useState({
    latitude: 45.497216,
    longitude: -73.610364,
    zoom: 8,
  });

  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };
      handleChange(
        document.getElementsByClassName("mapboxgl-ctrl-geocoder--input")[0]
          .value,
        newViewport
      );

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  useEffect(() => {
    if (mapRef) {
      let element = document.getElementsByClassName(
        "mapboxgl-ctrl-geocoder--input"
      );
      if (element[0]) {
        element[0].placeholder = "Property Address";
        element[0].value = defaultValue;
      }
    }
  }, [mapRef.current]);

  return (
    <div style={{ height: "50vh" }} className="map-geo">
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        <Geocoder
          mapRef={mapRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          position="top-left"
        />
      </MapGL>
    </div>
  );
};
export default GeoCodingMap;
