import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JoulnarCard from "../../../common/components/JoulnarCard";
import SingleMap from "../../../common/components/SingleMap";

const ListingsContainer = ({ children, bg, bgcolor, listing }) => {
  return (
    <>
      <div
        className="upper-bg listings-bg"
        style={{ backgroundImage: `url(${bg})`, direction: "ltr" }}
      >
        <h3
          style={{
            color: "#fff",
          }}
        >
          {listing.type} - {listing.listingType}
        </h3>
        <h3
          style={{
            background: "#fff",
            width: "fit-content",
            padding: "0.3rem",
            borderRadius: "5px",
          }}
        >
          ${listing.price} {listing.listingName}
        </h3>
        <p
          style={{
            background: "#fff",
            width: "fit-content",
            padding: "0.3rem",
            borderRadius: "5px",
          }}
        >
          <i className="las la-street-view" /> {listing.address}
        </p>
      </div>
      <Container fluid className="bs-container">
        <Row className="justify-content-center ">
          <Col
            lg={7}
            md={8}
            sm={9}
            xs={11}
            className="bs-body"
            style={{ background: bgcolor }}
          >
            {children}
          </Col>
          <Col lg={3} md={8} sm={9} xs={11}>
            <JoulnarCard reference={true} referenceInfo={listing.reference} />
          </Col>
        </Row>
      </Container>
      <div className="listing-map" style={{ marginBottom: "-3rem" }}>
        <SingleMap listing={listing} />
      </div>
    </>
  );
};

export default ListingsContainer;
