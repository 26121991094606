const SeventhPage = ({
  listing,
  handleChange,
  handleIncrement,
  handleDecrement,
}) => {
  return (
    <div className="seventh-page">
      <h5>Rooms</h5>
      <section style={{ marginBottom: 0 }}>
        <table>
          <thead>
            <tr>
              <th>Level</th>
              <th>Name</th>
              <th>Dimensions</th>
              <th>Flooring</th>
              <th>Informations</th>
            </tr>
          </thead>
          <tbody>
            {listing.roomsLevel.map((element, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name="level"
                    value={element.level}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={element.name}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="dimensions"
                    value={element.dimensions}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="flooring"
                    value={element.flooring}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="informations"
                    value={element.informations}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="buttons"
          style={{
            justifyContent: "center",
            marginTop: "0.5rem",
            marginBottom: "0",
          }}
        >
          <button style={{ marginRight: "0.6rem" }} onClick={handleDecrement}>
            -
          </button>
          <button onClick={handleIncrement}>+</button>
        </div>
      </section>
    </div>
  );
};

export default SeventhPage;
