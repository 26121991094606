import { useState } from "react";
import { addListing, uploadListingImage } from "../../../../api/sellerBuyerApi";
import EighthPage from "./EighthPage";
import FifthPage from "./FifthPage";
import FirstPage from "./FirstPage";
import FourthPage from "./FourthPage";
import SecondPage from "./SecondPage";
import SeventhPage from "./SeventhPage";
import SixthPage from "./SixthPage";
import ThirdPage from "./ThirdPage";
import { toast, ToastContainer } from "react-toastify";

const AddListings = () => {
  const [index, setIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const [listing, setListing] = useState({
    type: "",
    listingType: "",
    listingName: "",
    address: "",
    price: "",
    area: "",
    rooms: "",
    bedrooms: "",
    bathrooms: "",
    powderRooms: "",
    descriptionHeader: "",
    descriptionContent: "",
    inclusion: "",
    exclusion: "",
    reference: "",
    units: "",
    municipalTax: "",
    schoolTax: "",
    sewageSystem: "",
    waterSupply: "",
    foundation: "",
    roofing: "",
    siding: "",
    windows: "",
    windowType: "",
    heatingEnergy: "",
    heatingSystem: "",
    bathroomWashroom: "",
    hearthStove: "",
    cupboard: "",
    equipmentAvailable: "",
    pool: "",
    parking: "",
    garage: "",
    landscaping: "",
    proximity: "",
    zoning: "",
    land: "",
    typeOfProperty: "",
    typeOfBuilding: "",
    buildingDimensions: "",
    landDimensions: "",
    occupationPeriod: "",
    year: "",
    landEvaluation: "",
    buildingEvaluation: "",
    total: "",
    municipalTaxes: "",
    schoolTaxes: "",
    totalTaxes: "",
    longitude: "",
    latitude: "",
    rentalAppliance: "",
    view: "",
    yearOfConstruction: "",
    unitComposition: [
      {
        unitType: "",
        unitTotal: "",
        totalVacant: "",
        surface: "",
        description: "",
      },
    ],
    roomsLevel: [
      {
        level: "",
        name: "",
        dimensions: "",
        flooring: "",
        informations: "",
      },
    ],
  });

  const [gallery, setGallery] = useState([]);

  const handleChange = ({ currentTarget }) => {
    setListing((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
    if (currentTarget.name === "landEvaluation") {
      setListing((prev) => {
        return {
          ...prev,
          total: (
            parseInt(currentTarget.value) +
            (listing.buildingEvaluation !== ""
              ? parseInt(listing.buildingEvaluation)
              : 0)
          ).toString(),
        };
      });
    } else if (currentTarget.name === "buildingEvaluation") {
      setListing((prev) => {
        return {
          ...prev,
          total: (
            parseInt(currentTarget.value) +
            (listing.landEvaluation !== ""
              ? parseInt(listing.landEvaluation)
              : 0)
          ).toString(),
        };
      });
    } else if (currentTarget.name === "municipalTaxes") {
      setListing((prev) => {
        return {
          ...prev,
          totalTaxes: (
            parseInt(currentTarget.value) +
            (listing.schoolTaxes !== "" ? parseInt(listing.schoolTaxes) : 0)
          ).toString(),
        };
      });
    } else if (currentTarget.name === "schoolTaxes") {
      setListing((prev) => {
        return {
          ...prev,
          totalTaxes: (
            parseInt(currentTarget.value) +
            (listing.municipalTaxes !== ""
              ? parseInt(listing.municipalTaxes)
              : 0)
          ).toString(),
        };
      });
    }
  };

  const handleUnitIncrement = () => {
    let composition = [...listing.unitComposition];
    composition.push({
      unitType: "",
      unitTotal: "",
      totalVacant: "",
      surface: "",
      description: "",
    });
    setListing((prev) => {
      return { ...prev, unitComposition: composition };
    });
  };

  const handleUnitDecrement = () => {
    let composition = [...listing.unitComposition];
    composition.pop();
    setListing((prev) => {
      return { ...prev, unitComposition: composition };
    });
  };

  const handleUnitChange = (index, { currentTarget }) => {
    let composition = [...listing.unitComposition];
    composition[index][currentTarget.name] = currentTarget.value;
    setListing((prev) => {
      return { ...prev, unitComposition: composition };
    });
  };

  const handleRoomIncrement = () => {
    let composition = [...listing.roomsLevel];
    composition.push({
      level: "",
      name: "",
      dimensions: "",
      flooring: "",
      informations: "",
    });
    setListing((prev) => {
      return { ...prev, roomsLevel: composition };
    });
  };

  const handleRoomDecrement = () => {
    let composition = [...listing.roomsLevel];
    composition.pop();
    setListing((prev) => {
      return { ...prev, roomsLevel: composition };
    });
  };

  const handleRoomChange = (index, { currentTarget }) => {
    let composition = [...listing.roomsLevel];
    composition[index][currentTarget.name] = currentTarget.value;
    setListing((prev) => {
      return { ...prev, roomsLevel: composition };
    });
  };

  const handleImagesChange = ({ currentTarget }) => {
    setGallery(currentTarget.files);
  };

  const handleMapChange = (_, viewPort) => {
    setListing((prev) => {
      return {
        ...prev,
        longitude: viewPort.longitude.toString(),
        latitude: viewPort.latitude.toString(),
      };
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let { data } = await addListing(listing);
      await uploadListingImage(gallery, data.data);
      setLoading(false);
      toast.success("Listing has been added successfully");
    } catch (err) {
      setLoading(false);
      toast.error("Error, try again");
    }
  };

  return (
    <div className="sb-admin add-listing">
      <ToastContainer />
      <h4>Add new listing</h4>
      {index === 0 && (
        <FirstPage listing={listing} handleChange={handleChange} />
      )}
      {index === 1 && (
        <SecondPage listing={listing} handleChange={handleChange} />
      )}
      {index === 2 && (
        <ThirdPage listing={listing} handleChange={handleChange} />
      )}
      {index === 3 && (
        <FourthPage listing={listing} handleChange={handleChange} />
      )}
      {index === 4 && (
        <FifthPage listing={listing} handleChange={handleChange} />
      )}
      {index === 5 && (
        <SixthPage
          listing={listing}
          handleChange={handleUnitChange}
          handleDecrement={handleUnitDecrement}
          handleIncrement={handleUnitIncrement}
        />
      )}
      {index === 6 && (
        <SeventhPage
          listing={listing}
          handleChange={handleRoomChange}
          handleIncrement={handleRoomIncrement}
          handleDecrement={handleRoomDecrement}
        />
      )}
      {index === 7 && (
        <EighthPage
          listing={listing}
          gallery={gallery}
          handleChange={handleImagesChange}
          handleSubmit={handleSubmit}
          handleMapChange={handleMapChange}
        />
      )}

      <div className="buttons">
        <button
          onClick={() => {
            if (index > 0) setIndex(index - 1);
          }}
        >
          Prev
        </button>
        <button
          onClick={() => {
            if (index < 7) setIndex(index + 1);
            else handleSubmit();
          }}
          disabled={loading}
        >
          {index < 7 ? (
            "Next"
          ) : loading ? (
            <div className="main-loader"></div>
          ) : (
            "Send"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddListings;
