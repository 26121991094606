import "./app.css";

import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../features/structure/components/Header";
import SellerGuide from "../features/seller and buyer/components/SellerGuide";
import HomeValue from "../features/seller and buyer/components/HomeValue";
import BuyerGuide from "../features/seller and buyer/components/BuyerGuide";
import FindProperty from "../features/seller and buyer/components/FindProperty";
import Testimonials from "../features/testimonials/components/Testimonials";
import Contact from "../features/structure/components/Contact";
import About from "../features/structure/components/About";
import Listings from "../features/listings/components/Listings";
import Login from "../features/authentication/components/Login";
import Blogs from "../features/blogs/components/Blogs";
import SingleBlog from "../features/blogs/components/SingleBlog";
import SingleNeighborhood from "../features/neighborhoods/components/SingleNeighborhood";
import SpecificListing from "../features/listings/components/SpecificListing";
import Footer from "../features/structure/components/Footer";
import Home from "../features/home/components/Home";
import { useState } from "react";

// resetting

const App = () => {
  const [privacy, setPrivacy] = useState(false);

  return (
    <>
      <Header />
      <Switch>
        <Route path="/seller-guide" component={SellerGuide} />
        <Route path="/home-value" component={HomeValue} />
        <Route path="/buyer-guide" component={BuyerGuide} />
        <Route path="/find-property" component={FindProperty} />
        <Route path="/testimonials" component={Testimonials} />
        <Route path="/contact" component={Contact} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/listings/:id" component={SpecificListing} />
        <Route path="/listings" component={Listings} />
        <Route path="/blogs/:id" component={SingleBlog} />
        <Route path="/blogs" component={Blogs} />
        <Route path="/neighborhoods/:id" component={SingleNeighborhood} />
        <Redirect to="/blogs" />
      </Switch>

      {!localStorage.getItem("accepted") &&
        sessionStorage.getItem("accepted") !== "false" && (
          <div
            style={{
              zIndex: 10000,
              position: "fixed",
              bottom: 0,
              padding: "4rem 1rem",
              background: "#000",
              color: "#fff",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingBottom: "1rem",
              direction: "ltr",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "2rem",
                direction: "ltr",
              }}
              className="cookie"
            >
              <h2>This website uses cookies to enhance the user experience</h2>
              <div style={{ direction: "ltr", gap: "1rem" }}>
                <button
                  style={{
                    padding: "0.3rem 1rem",
                    borderRadius: "10px",
                    border: "none",
                    marginRight: "1rem",
                  }}
                  onClick={() => setPrivacy(!privacy)}
                >
                  Privacy Policy
                </button>
                <button
                  style={{
                    padding: "0.3rem 1rem",
                    borderRadius: "10px",
                    border: "none",
                    background: "#e84042",
                    color: "#fff",
                    marginRight: "1rem",
                  }}
                  onClick={() => {
                    sessionStorage.setItem("accepted", false);
                    window.location = "/";
                  }}
                >
                  Reject
                </button>
                <button
                  style={{
                    padding: "0.3rem 1rem",
                    borderRadius: "10px",
                    border: "none",
                    background: "green",
                    color: "#fff",
                  }}
                  onClick={() => {
                    localStorage.setItem("accepted", true);
                    window.location = "/";
                  }}
                >
                  Accept
                </button>
              </div>
            </div>

            {privacy && (
              <div>
                {localStorage.getItem("lang") === "fr" ? (
                  <pre
                    style={{
                      height: "250px",
                      overflowY: "scroll",
                      direction: "ltr",
                    }}
                  >
                    Politique de confidentialité: Joulnar El Husseini-Mccormick
                    fournit un environnement web sécuritaire à ses utilisateurs
                    Internet. Le COURTIER est régi par la Loi sur la protection
                    des renseignements personnels dans le secteur privé (RLRQ,
                    c. P-39.1) (ci-après, la « Loi ») et à ce titre le COURTIER
                    s’efforce à préserver les normes de confidentialité les plus
                    élevées par rapport aux renseignements personnels que ses
                    clients, fournisseurs et les autres entreprises lui
                    fournissent dans le cadre de ses actes de courtage
                    immobilier, notamment mais sans limitation, par l’entremise
                    du site web du COURTIER (le « Site Web »), de ses réseaux
                    sociaux, de ses publicités ainsi que pour les fins de ses
                    systèmes de Gestion de la Relation Client (GRC) et de
                    Gestion Électronique des Documents (GED) et ce selon les
                    obligations lui incombant aux termes de la Loi. Qu’entend-on
                    par « renseignements personnels »? Un renseignement
                    personnel est un renseignement qui concerne une personne
                    physique et qui permet, directement ou indirectement, de
                    l’identifier. Un écrit, une image, une vidéo et un
                    enregistrement sonore peuvent contenir des renseignements
                    personnels. Dans le cadre de ses activités professionnelles,
                    le COURTIER peut recueillir des renseignements personnels
                    tels que le nom, l’adresse domiciliaire, la date de
                    naissance, les renseignements d’une pièce d’identité, le
                    numéro d’assurance sociale, les renseignements sur les
                    revenus, la situation matrimoniale d’une personne, etc. Les
                    principes de protection énumérés ci-dessous s’appliquent à
                    la collecte, l’utilisation et la transmission des
                    renseignements personnels que les utilisateurs Internet
                    pourraient fournir dans le cadre de l’utilisation du Site
                    Web. 1. Responsabilité Le COURTIER est responsable de la
                    protection des renseignements personnels qu’il détient dans
                    le cadre de l’exercice des activités du courtage immobilier.
                    Dans ce but, le COURTIER a adopté la politique de
                    confidentialité ainsi que des politiques et des pratiques
                    encadrant la gouvernance à l’égard des renseignements
                    personnels et dont l’objectif est d’encadrer la cueillette,
                    l’utilisation, la communication, la conservation et la
                    destruction des renseignements personnels. 2. Collecte de
                    renseignements personnels Le COURTIER ne collecte que des
                    renseignements personnels nécessaires à l’exercice de ses
                    activités dans le domaine du courtage immobilier. À titre
                    d’exemple, il peut s’agir de renseignements collectés aux
                    fins de la réalisation d’une transaction immobilière, aux
                    fins de la tenue des dossiers, de la surveillance de la
                    pratique professionnelle par l’Organisme
                    d’autoréglementation du courtage immobilier du Québec
                    (l’OACIQ) ou toute autre fin déterminée par le COURTIER et
                    portée à la connaissance de la personne dont on demande le
                    consentement. Le COURTIER invite les membres de son
                    personnel, de son équipe ou tout adjoint administratif,
                    selon le cas, à expliquer en termes simples et clairs à la
                    personne concernée les raisons de la collecte de ses
                    renseignements personnels et à s’assurer de leur
                    compréhension. Aux fins de la collecte des renseignements
                    personnels, le COURTIER encourage les membres de son
                    personnel, de son équipe ou tout adjoint administratif,
                    selon le cas, à utiliser les formulaires standardisés
                    élaborés par l’OACIQ. Le COURTIER peut aussi recueillir les
                    renseignements personnels verbalement lors de
                    correspondances avec les personnes impliquées dans une
                    transaction ou par le biais de divers documents soumis dans
                    le cadre d’une réalisation d’une transaction immobilière
                    (pièces d’identité, documents financiers, procurations,
                    etc.). 3. Utilisation et communications des renseignements
                    personnels Les renseignements personnels sont utilisés et
                    communiqués aux fins pour lesquelles ils ont été collectés
                    et avec le consentement de la personne concernée. Dans
                    certains cas prévus par la loi, les renseignements
                    personnels peuvent être utilisés à d’autres fins, par
                    exemple, dans le but notamment de détecter et de prévenir
                    une fraude ou dans le but de fournir un service à la
                    personne concernée. Le COURTIERpeut être tenu de communiquer
                    les renseignements personnels à des tiers, par exemple, aux
                    fournisseurs, aux cocontractants, aux sous-traitants, aux
                    mandataires, aux assureurs (comme le Fonds d’assurance
                    responsabilité professionnelle du courtage immobilier du
                    Québec [le FARCIQ], le Fonds d’indemnisation du courtage
                    immobilier du Québec [le FICI]), aux professionnels, aux
                    institutions financières ou de vérification de crédit ainsi
                    qu’aux fournisseurs de systèmes de gestion électronique de
                    documents (GED) ainsi qu’à d’autres régulateurs, ou à
                    l’extérieur du Québec. Le COURTIER peut, sans le
                    consentement de la personne concernée, communiquer un
                    renseignement personnel à un tiers si cette communication
                    est nécessaire à l’exécution d’un mandat ou d’un contrat de
                    service ou d’entreprise. Dans ce cas, le COURTIER établit un
                    mandat ou un contrat écrit dans lequel il indique les
                    mesures que son mandataire doit prendre pour assurer la
                    protection des renseignements personnels qui lui sont
                    confiés, pour que ceux-ci ne soient utilisés que dans
                    l’exercice du mandat ou du contrat et qu’ils soient détruits
                    après sa fin. Le cocontractant doit également s’obliger à
                    collaborer avec le COURTIER en cas de violation de la
                    confidentialité des renseignements personnels. Avant de
                    communiquer les renseignements personnels à l’extérieur du
                    Québec, le COURTIER tient compte de leur sensibilité, de la
                    finalité de leur utilisation et des mesures de protection
                    dont ceux-ci bénéficieront à l’extérieur du Québec. Le
                    COURTIER ne communiquera les renseignements personnels à
                    l’extérieur du Québec que si son analyse démontre que
                    ceux-ci bénéficieront d’une protection adéquate dans
                    l’endroit où ils doivent être communiqués. 4. Conservation
                    et destruction des renseignements personnels Lorsque les
                    fins auxquelles les renseignements personnels ont été
                    recueillis ou utilisés ont été accomplies, le COURTIER doit
                    les détruire, sous réserve d’un délai de conservation prévu
                    par la Loi. À cet égard, les obligations professionnelles du
                    COURTIER l’obligent à conserver ses dossiers pendant au
                    moins six (6) ans suivant leur fermeture définitive. 5.
                    Mesures de sécurité Lors de la collecte, de l’utilisation,
                    de la conservation et de la destruction des renseignements
                    personnels, le COURTIER applique les mesures de sécurité
                    nécessaires pour protéger le caractère confidentiel des
                    renseignements personnels. Les données personnelles
                    recueillies seront stockées notamment sur des serveurs et
                    dans des systèmes de gestion électronique des données
                    disposant des mécanismes et utilisant des normes reconnues
                    en matière de protection pour assurer la sécurité des
                    renseignements personnels que ses clients, fournisseurs et
                    les autres entreprises lui fournissent dans le cadre de ses
                    actes de courtage immobilier, notamment mais sans
                    limitation, par l’entremise du Site Web, de ses réseaux
                    sociaux, de ses publicités ainsi que pour les fins de ses
                    systèmes de Gestion de la Relation Client (GRC) et de
                    Gestion Électronique des Documents (GED) et ce selon les
                    obligations lui incombant aux termes de la Loi. Incident de
                    confidentialité Est un incident de confidentialité l’accès,
                    l’utilisation, la communication d’un renseignement personnel
                    non autorisé par la Loi ou la perte d’un renseignement
                    personnel ou toute autre atteinte à la protection d’un
                    renseignement personnel. Le COURTIER a mis en place un
                    protocole de gestion d’un incident de confidentialité dans
                    lequel sont identifiées les personnes qui assistent le
                    Responsable de la protection des renseignements personnels
                    et qui prévoit les actions concrètes qui doivent être posées
                    en cas d’incident. Ce protocole prévoit notamment les
                    responsabilités attendues à chacune des étapes de la gestion
                    de l’incident incluant les mesures à prendre pour assurer la
                    sécurité des données. 6. A. B. C. Rôles et responsabilités
                    Le COURTIER, son personnel, les membres de son équipe ou
                    tout adjoint administratif, selon le cas ● Assure la
                    confidentialité des renseignements par de bonnes pratiques
                    de gestion de l’information. Plus particulièrement, il donne
                    des directives, des formations et des instructions aux
                    membres du personnel relatives à la collecte, à l’usage, au
                    stockage, à la modification, à la consultation, à la
                    communication et à la destruction permise des renseignements
                    personnels. ● Déploie les mesures de protection adéquates
                    afin de réduire le risque d’incident de confidentialité, par
                    exemple, la sécurité informatique, la mise à jour des
                    politiques relatives aux renseignements personnels, la
                    formation de son personnel, etc. ● Disposedeméthodes
                    uniformisées de classement des documents contenant des
                    renseignements personnels. ● Dispose de méthodes
                    uniformisées de conservation des documents contenant des
                    renseignements personnels, notamment quant à la procédure de
                    numérisation. ● Gèrelesaccès physique et informatique aux
                    renseignements personnels en fonction notamment de leur
                    sensibilité. ● Procède à la destruction sécurisée des
                    renseignements personnels. Plus particulièrement, il donne
                    des directives ou des instructions aux membres du personnel
                    relatives à la méthode de destruction sécuritaire, aux
                    délais de destruction, etc. Responsable de la protection des
                    renseignements personnels Conformément à la Loi, le COURTIER
                    est le Responsable de la protection des renseignements
                    personnels. Il s’assure notamment que les présentes
                    politiques sont respectées et qu’elles sont conformes à la
                    réglementation applicable. Le nom et les coordonnées de
                    cette personne figurent dans la section « Plaintes ». Le
                    COURTIER assume la gestion des incidents de confidentialité
                    et, dans ce contexte, pose des gestes prévus par la Loi. Le
                    COURTIER traite les demandes d’accès et de rectification des
                    renseignements personnels. Il traite également les plaintes
                    relativement au traitement des renseignements personnels par
                    le COURTIER. Le COURTIER, établit des facteurs relatifs à la
                    vie privée pour tout projet d’acquisition, de développement
                    et de refonte d’un système d’information ou de prestation
                    électronique de services impliquant la collecte,
                    l’utilisation, la communication, la conservation ou la
                    destruction de renseignements personnels. Il peut suggérer
                    des mesures pour assurer la protection de renseignements
                    personnels dans le cadre d’un tel projet. Membres du
                    personnel, de l’équipe ou tout adjoint administratif Un
                    membre du personnel, de l’équipe ou tout adjoint
                    administratif du COURTIER peut prendre connaissance du
                    renseignement personnel uniquement dans la mesure où cela
                    est indispensable à l’exécution de ses fonctions ou de son
                    mandat. Le membre du personnel, de l’équipe ou tout adjoint
                    administratif du COURTIER : ● S’assure de l’intégrité et de
                    la confidentialité des renseignements personnels détenus le
                    COURTIER. ● Se conforme à toutes les politiques et
                    directives du COURTIER sur l’accès, la collecte,
                    l’utilisation, la communication, la destruction des
                    renseignements personnels et sur la sécurité de
                    l’information et respecte les consignes qui lui sont
                    présentées. ● Respecte les mesures de sécurité mises en
                    place sur son poste de travail et sur tout équipement
                    contenant des renseignements personnels. ● Utilise
                    uniquement l’équipement et les logiciels autorisés par le
                    COURTIER. ● Assure, le moment venu, la destruction
                    sécuritaire des renseignements personnels conformément aux
                    consignes reçues. Signale immédiatement à son supérieur tout
                    acte, dont il a connaissance, susceptible de constituer une
                    violation réelle ou présumée des règles de sécurité
                    relatives aux renseignements personnels. 7. Droit d’accès,
                    de retrait et de rectification Une personne (ou son
                    représentant autorisé) peut demander d’avoir accès aux
                    renseignements personnels la concernant détenus le COURTIER.
                    Une personne peut retirer à tout moment son consentement à
                    la collecte, à l’utilisation et à la communication de ses
                    renseignements personnels. Ce retrait est alors consigné par
                    écrit. Une personne peut demander de corriger, dans un
                    dossier qui la concerne, des renseignements personnels
                    qu’elle estime inexacts, incomplets ou équivoques. Une
                    personne peut demander de transférer à un tiers, par voie
                    électronique, les données personnelles que le COURTIER
                    détient sur elle. Le COURTIER peut refuser une demande
                    d’accès ou de rectification dans les cas prévus par la Loi
                    (https://www.legisquebec.gouv.qc.ca/fr/document/lc/P-39.1).
                    8. Plaintes Une personne qui se considère lésée peut
                    formuler une plainte relativement au traitement de ses
                    renseignements personnels par le COURTIER. Cette plainte
                    sera traitée avec diligence dans un délai maximal de 30
                    jours par le COURTIER et une réponse écrite vous sera
                    adressée. Pour faire une demande d’accès, de rectification
                    de vos renseignements personnels ou toute autre demande à
                    laquelle vous avez droit ainsi que pour soumettre une
                    plainte relativement au traitement des renseignements
                    personnels, veuillez communiquer avec : Joulnar El
                    Husseini-Mccormick 1280 Av. Bernard Ouest, Outremont, QC,
                    H2V 3Z9 Tél: 514 970 3704 Courriel: joulnar@sutton.com
                  </pre>
                ) : (
                  <pre
                    style={{
                      height: "250px",
                      overflowY: "scroll",
                      direction: "ltr",
                    }}
                  >
                    Privacy policy: Joulnar El Husseini-Mccormick hereinafter,
                    the "BROKER") provides a secure Web environment to its
                    Internet users. The BROKERis governed by the Act respecting
                    the protection of personal information in the private sector
                    (RLRQ, c. P-39. 1) (hereinafter, the "Act") and as such the
                    BROKER endeavours to maintain the highest standards of
                    confidentiality with respect to personal information
                    provided to it by its clients, suppliers and other
                    businesses in the course of its real estate brokerage
                    activities, including but not limited to, through the
                    BROKER's website (the "Website"), its social networks, its
                    advertisements and for the purposes of its Customer
                    Relationship Management (CRM) and Electronic Document
                    Management (EDM) systems, in accordance with its obligations
                    under the Act. What is meant by « personal information »?
                    Personal information is information about a natural person
                    that directly or indirectly allows that person to be
                    identified. A written document, an image, a video or a sound
                    recording may contain personal information. In the course of
                    its professional activities, the BROKER may collect personal
                    information such as a person's name, home address, date of
                    birth, identity document information, social insurance
                    number, income information, marital status, etc. The
                    protection principles listed below apply to all personal
                    information collected by the BROKER. The protection
                    principles listed below apply to the collection, use and
                    transmission of personal information that Internet users may
                    provide when using the Website. 1. Responsibility The BROKER
                    is responsible for protecting the personal information it
                    holds in the course of real estate brokerage activities. To
                    this end, the BROKER has adopted a confidentiality policy as
                    well as policies and practices governing the governance of
                    personal information, the purpose of which is to govern the
                    collection, use, communication, retention and destruction of
                    personal information. 2. Collection of personal information
                    The BROKER collects only the personal information required
                    to carry out its real estate brokerage activities. For
                    example, this information may be collected for the purposes
                    of carrying out a real estate transaction, maintaining
                    files, monitoring professional practice by the Organisme
                    d'autoréglementation du courtage immobilier du Québec
                    (OACIQ) or any other purpose determined by the BROKER and
                    brought to the attention of the person whose consent is
                    being sought. The BROKER invites the members of its staff,
                    its team or any administrative assistant, as the case may
                    be, to explain in simple and clear terms to the person
                    concerned the reasons for collecting his or her personal
                    information and to ensure that they understand. For the
                    purposes of collecting personal information, the BROKER
                    encourages the members of its staff, team or any
                    administrative assistant, as the case may be, to use the
                    standardized forms developed by the OACIQ. The BROKER may
                    also collect personal information verbally during
                    correspondence with the persons involved in a transaction or
                    through various documents submitted as part of a real estate
                    transaction (identification, financial documents, powers of
                    attorney, etc.). 3. Use and disclosure of personal
                    information Personal information is used and communicated
                    for the purposes for which it was collected and with the
                    consent of the person concerned. In certain cases provided
                    for by law, personal information may be used for other
                    purposes, for example, to detect and prevent fraud or to
                    provide a service to a person. The BROKER may berequired to
                    communicate personal information to third parties, for
                    example, suppliers, co-contractors, subcontractors, agents,
                    insurers (such as the Fonds d'assurance responsabilité
                    professionnelle du courtage immobilier du Québec [FARCIQ],
                    the Fonds d'indemnisation du courtage immobilier du Québec
                    [FICI]), professionals, financial or credit verification
                    institutions, electronic document management (EDM) system
                    providers and other regulators, or outside Québec. The
                    BROKER may, without the consent of the person concerned,
                    communicate personal information to a third party if such
                    communication is necessary for the execution of a mandate or
                    a service or business contract. In this case, the BROKER
                    shall draw up a written mandate or contract in which he
                    shall indicate the measures that his mandatary must take to
                    ensure the protection of the personal information entrusted
                    to him, to ensure that it is used only in the performance of
                    the mandate or contract and that it is destroyed once the
                    mandate or contract has ended. The co-contractor must also
                    undertake to cooperate with the BROKER in the event of a
                    breach of confidentiality of personal information. Before
                    communicating personal information outside Québec, the
                    BROKER takes into account the sensitivity of the
                    information, the purpose for which it is to be used and the
                    protection measures that will be in place outside Québec.
                    The BROKER will communicate personal information outside
                    Quebec only if its analysis demonstrates that it will
                    benefit from adequate protection in the place where it is to
                    be communicated. 4. Retention and destruction of personal
                    information Once the purposes for which the personal
                    information was collected or used have been fulfilled, the
                    BROKER must destroy it, subject to a retention period
                    stipulated by law. In this regard, the BROKER's professional
                    obligations require it to keep its files for at least six
                    (6) years following their final closure. 5. Security
                    measures When collecting, using, storing and destroying
                    personal information, the BROKER applies the necessary
                    security measures to protect the confidentiality of personal
                    information. The personal data collected will be stored on
                    servers and in electronic data management systems equipped
                    with mechanisms and using recognized standards of protection
                    to ensure the security of personal information provided to
                    the BROKER by its clients, suppliers and other companies in
                    the course of its real estate brokerage activities, in
                    particular but without limitation, through the Website, its
                    social networks, its advertisements and for the purposes of
                    its Customer Relationship Management (CRM) and Electronic
                    Document Management (EDM) systems, in accordance with its
                    obligations under the Act. Privacy incident A
                    confidentiality incident is any access, use or disclosure of
                    personal information that is not authorized by the Act, or
                    the loss of personal information or any other breach in the
                    protection of personal information. The BROKER has
                    established a protocol for managing a confidentiality
                    incident, which identifies the persons who assist the
                    Privacy Officer and sets out the concrete actions to be
                    taken in the event of an incident. This protocol sets out
                    the responsibilities expected at each stage of incident
                    management, including the measures to be taken to ensure
                    data security. 6. A. B. C. 7. Roles and responsibilites The
                    BROKER, his staff, the members of his team or any
                    administrative assistant, as the case may be ● Ensures the
                    confidentiality of information through good information
                    management practices. In particular, it provides guidelines,
                    training and instructions to staff members on the
                    collection, use, storage, modification, consultation,
                    communication and permitted destruction of personal
                    information. ● Adopts appropriate safeguards to reduce the
                    risk of confidentiality incidents, e.g. computer security,
                    updating personal information policies, staff training, etc.
                    ● Hasstandardised methods for filing documents containing
                    personal information. ● Hasstandardised methods for
                    conserving documents containing personal information,
                    particularly with regard to the digitisation procedure. ●
                    Managesphysical and IT access to personal information,
                    depending in particular on its sensitivity. ● Securely
                    destroys personal information. In particular, he/she gives
                    directives or instructions to staff members regarding the
                    secure destruction method, destruction deadlines, etc.
                    Person responsible for the protection of personal
                    information In accordance with the Act, the BROKER is
                    responsible for the protection of personal information. In
                    particular, he or she ensures that these policies are
                    complied with and that they comply with the applicable
                    regulations. The name and contact details of this person
                    appear in the "Complaints" section. The BROKER is
                    responsible for managing confidentiality incidents and, in
                    this context, takes the actions provided for by the Act. The
                    BROKER handles requests for access to and rectification of
                    personal information. It also handles complaints relating to
                    the processing of personal information by the BROKER. The
                    BROKER establishes privacy factors for any project involving
                    the acquisition, development or redesign of an information
                    system or the electronic delivery of services involving the
                    collection, use, disclosure, retention or destruction of
                    personal information. It may suggest measures to ensure the
                    protection of personal information as part of such a
                    project. Members of staff, team or any administrative
                    assistant A member of the BROKER's staff, team or any of the
                    BROKER's administrative assistants may inspect personal
                    information only insofar as this is essential for the
                    performance of his duties or his mandate. The member of
                    staff, the team or any administrative assistant of the
                    BROKER : ● Ensuresthe integrity and confidentiality of the
                    personal information held by the BROKER. ● Complies with all
                    the BROKER's policies and directives on access, collection,
                    use, disclosure and destruction of personal information and
                    on information security, and complies with the instructions
                    provided. ● Respects the security measures in place at the
                    workstation and on any equipment containing personal
                    information. ● Useonlytheequipment and software authorised
                    by the BROKER. ● Ensures, when the time comes, that personal
                    information is securely destroyed in accordance with
                    instructions received. Immediately report to his/her
                    superior any act of which he/she is aware that may
                    constitute an actual or suspected breach of security rules
                    relating to personal information. Right of access,
                    withdrawal and rectification An individual (or his/her
                    authorized representative) may request access to his/her
                    personal information held by the BROKER. An individual may
                    withdraw his or her consent to the collection, use and
                    disclosure of his or her personal information at any time.
                    This withdrawal will be recorded in writing. An individual
                    may ask to correct, in a file concerning him or her,
                    personal information that he or she believes to be
                    inaccurate, incomplete or equivocal. Aperson may request
                    that personal information held by the BROKER be transferred
                    electronically to a third party. The BROKER may refuse a
                    request for access or rectification in the cases provided
                    for by the Act
                    (https://www.legisquebec.gouv.qc.ca/fr/document/lc/P-39.1).
                    8. Complaints A person who considers that he or she has been
                    wronged may lodge a complaint regarding the processing of
                    his or her personal information by the BROKER. This
                    complaint will be dealt with diligently by the BROKER within
                    a maximum of 30 days and a written reply will be sent to
                    you. To make a request for access, rectification of your
                    personal information or any other request to which you are
                    entitled, as well as to submit a complaint relating to the
                    processing of personal information, please contact : Joulnar
                    El Husseini-Mccormick 1280 Av. Bernard Ouest, Outremont, QC,
                    H2V 1V9 Tél: 514 970 3704 Email: joulnar@sutton.com
                  </pre>
                )}
              </div>
            )}
          </div>
        )}
      <Footer />
    </>
  );
};

export default App;
