import React from "react";
import SellerContainer from "./SellerContainer";

import seller from "../../../images/seller.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sellerGuideReducer } from "../sellerBuyerSlice";
import { toast, ToastContainer } from "react-toastify";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import back from "../../../images/home.jpg";

const SellerGuide = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sellerBuyer.isLoading);
  const image = useSelector((state) => state.admin.images);

  const [sellerGuide, setSellerGuide] = useState({
    name: "",
    phone: "",
    email: "",
    helpType: new Map(),
    address: "",
    notes: "",
  });

  const { sellerBuyer } = getLanguageObject();

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    helpType: "",
  });

  const handleChange = ({ currentTarget }) => {
    if (errors[currentTarget.name] && currentTarget.value) {
      const errorsObj = { ...errors };
      errorsObj[currentTarget.name] = "";
      setErrors(errorsObj);
    } else if (errors[currentTarget.name] === "" && !currentTarget.value) {
      const errorsObj = { ...errors };
      errorsObj[currentTarget.name] = "error";
      setErrors(errorsObj);
    }
    if (
      currentTarget.name === "visit" ||
      currentTarget.name === "moreInfo" ||
      currentTarget.name === "priveteSession" ||
      currentTarget.name === "propertyAssessed"
    ) {
      let map = sellerGuide.helpType;
      if (map.has(currentTarget.name)) {
        map.delete(currentTarget.name);
      } else {
        map.set(currentTarget.name, currentTarget.value);
      }

      if (map.size !== 0 && errors.helpType) {
        const errorsObj = { ...errors };
        errorsObj["helpType"] = "";
        setErrors(errorsObj);
      }
      return setSellerGuide((prev) => {
        return { ...prev, helpType: map };
      });
    }
    setSellerGuide((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = () => {
    const errorsObj = { ...errors };
    let isErrors = false;
    for (let element in sellerGuide) {
      if (
        element === "name" ||
        element === "phone" ||
        element === "email" ||
        element === "helpType"
      ) {
        if (element === "helpType" && sellerGuide["helpType"].size === 0) {
          errorsObj[element] = "error";
          isErrors = true;
        }
        if (!sellerGuide[element]) {
          errorsObj[element] = "error";
          isErrors = true;
        }
      }
    }

    if (isErrors) toast.error("please fill the required fields");
    setErrors(errorsObj);

    if (!isErrors)
      dispatch(sellerGuideReducer(sellerGuide))
        .unwrap()
        .then(() => {
          toast.success("Your information was sent successfully");
          setSellerGuide({
            name: "",
            phone: "",
            email: "",
            helpType: new Map(),
            address: "",
            notes: "",
          });
        });
  };

  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <SellerContainer
      bg={image.sellerGuide ? image.sellerGuide : loadedImages ? back : ""}
      bgcolor="#fff"
    >
      <ToastContainer />
      <div className="seller-buyer">
        <header>{sellerBuyer.sellerGuide}</header>
        <p>{sellerBuyer.sellerGuidePar}</p>
        <a
          href="https://www.oaciq.com/uploads/ckeditor/attachments/402/sellers-guide.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <img src={seller} alt="seller's guide" />
        </a>
        <h6>* {sellerBuyer.clickOnImg}</h6>
        <div className="form">
          <section className={`input ${errors.name ? "error" : ""}`}>
            <label>
              {sellerBuyer.name} <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              value={sellerGuide.name}
              onChange={handleChange}
            />
          </section>
          <section className={`input ${errors.phone ? "error" : ""}`}>
            <label>
              {sellerBuyer.phone} <span>*</span>
            </label>
            <input
              type="text"
              name="phone"
              value={sellerGuide.phone}
              onChange={handleChange}
            />
          </section>
          <section className={`input ${errors.email ? "error" : ""}`}>
            <label>
              {sellerBuyer.email} <span>*</span>
            </label>
            <input
              type="text"
              name="email"
              value={sellerGuide.email}
              onChange={handleChange}
            />
          </section>
          <section className={`input ${errors.helpType ? "error" : ""}`}>
            <label className="error">
              {sellerBuyer.how} <span>*</span>
            </label>
            <div>
              <input
                type="checkbox"
                name="moreInfo"
                onChange={handleChange}
                value="I would like to receive more information on a property"
              />
              <p>{sellerBuyer.Iwould}</p>
            </div>
            <div>
              <input
                type="checkbox"
                name="visit"
                onChange={handleChange}
                value="I want to visit a property"
              />
              <p>{sellerBuyer.Iwant}</p>
            </div>
            <div>
              <input
                type="checkbox"
                name="propertyAssessed"
                onChange={handleChange}
                value="I wish to have my property assessed"
              />
              <p>{sellerBuyer.propertyAssessed}</p>
            </div>
            <div>
              <input
                type="checkbox"
                name="priveteSession"
                onChange={handleChange}
                value="I wish to have a private information session"
              />
              <p>{sellerBuyer.privateSession}</p>
            </div>
          </section>
          <section className="input">
            <label>{sellerBuyer.address}</label>
            <input
              type="text"
              name="address"
              value={sellerGuide.address}
              onChange={handleChange}
            />
          </section>
          <section className="input">
            <label>{sellerBuyer.notes}</label>
            <input
              type="text"
              name="notes"
              value={sellerGuide.notes}
              onChange={handleChange}
            />
          </section>
          <button onClick={handleSubmit} disabled={isLoading}>
            {isLoading && <div className="main-loader"></div>}
            {!isLoading && (
              <>
                <i className="lar la-paper-plane" />
                {sellerBuyer.send}
              </>
            )}
          </button>
        </div>
      </div>
    </SellerContainer>
  );
};

export default SellerGuide;
