import BlogsContainer from "./BlogsContainer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPublishedBlogsReducer } from "../../admin/adminSlice";
import { withRouter } from "react-router-dom";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import back from "../../../images/home.jpg";

const Blogs = (props) => {
  const blogs = useSelector((state) => state.admin.published);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublishedBlogsReducer());
  }, [dispatch]);

  const getDate = (date) => {
    let returnDate = new Date();
    returnDate.setTime(date);
    return returnDate.toDateString();
  };

  const getAllBody = (element) => {
    const json = JSON.parse(JSON.parse(element));

    let string = "";
    for (let i = 0; i < json.length; i++) {
      string +=
        json[i].header[localStorage.getItem("lang")] +
        " " +
        json[i].body[localStorage.getItem("lang")] +
        " ";
    }

    while (string.indexOf("`") !== -1) {
      const startIndex = string.indexOf("`");

      string = string.replace("`", "");
      const endIndex = string.indexOf("`");
      string = string.replace("`", "");

      string = string.replace(
        string.substring(startIndex, endIndex),
        `<a href=${string.substring(
          startIndex,
          endIndex
        )} rel="noreferrer" target="_blank">${string.substring(
          startIndex,
          endIndex
        )}</a>`
      );
    }

    return string;
  };

  const handleClick = (element) => {
    props.history.push({
      pathname: "/blogs/" + element.id,
      state: { element },
    });
  };

  const lang = getLanguageObject().testimonial;

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <BlogsContainer bg={image.blogs ? image.blogs : loadedImages ? back : ""}>
      <div className="blogs" style={{ paddingTop: 0 }}>
        <div className="data">
          <div className="content">
            {blogs
              .filter(
                (blog) =>
                  JSON.parse(JSON.parse(blog.body))[0].body[
                    localStorage.getItem("lang")
                  ] !== ""
              )
              .map((element, index) => (
                <div
                  className="single-data"
                  key={index}
                  style={{ borderRadius: "0.125rem" }}
                >
                  <div
                    style={{ backgroundImage: `url(${element.imageUrl})` }}
                  ></div>
                  <section>
                    <p>{getDate(element.date)}</p>
                    <h4
                      style={{
                        direction:
                          localStorage.getItem("lang") === "ar" ? "rtl" : "ltr",
                      }}
                    >
                      {
                        JSON.parse(element.subject)[
                          localStorage.getItem("lang")
                        ]
                      }
                    </h4>
                    <p
                      style={{
                        direction:
                          localStorage.getItem("lang") === "ar" ? "rtl" : "ltr",
                        textAlign:
                          localStorage.getItem("lang") === "ar"
                            ? "right !important"
                            : "left !important",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getAllBody(element.body),
                      }}
                    ></p>

                    <section>
                      <button onClick={() => handleClick(element)}>
                        {lang.read}
                      </button>
                    </section>
                  </section>
                </div>
              ))}
          </div>
        </div>
      </div>
    </BlogsContainer>
  );
};

export default withRouter(Blogs);
