import ar from "../../localization/keywords/ar.json";
import en from "../../localization/keywords/en.json";
import fr from "../../localization/keywords/fr.json";

export const getLanguageObject = () => {
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "en");
  }
  if (localStorage.getItem("lang") === "fr") return fr;
  else if (localStorage.getItem("lang") === "en") return en;

  return ar;
};
