import { useState } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { uploadContentImagesApi } from "../../../../api/sellerBuyerApi";

const Leads = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ currentTarget }) => {
    let index = images.findIndex((item) => item.type === currentTarget.name);
    let newImages = [...images];
    if (index === -1) {
      newImages.push({
        type: currentTarget.name,
        file: currentTarget.files[0],
      });
    } else {
      newImages[index] = {
        type: currentTarget.name,
        file: currentTarget.files[0],
      };
    }
    setImages(newImages);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await uploadContentImagesApi(images);
      toast.success("Images have been uploaded successfully");
      setLoading(false);
      setImages([]);
    } catch (err) {
      toast.error("Error!, try again");
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="sb-admin website-images">
        <h4>Website images management</h4>

        <section>
          <label>Profile Photo</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "profile") !== -1
                ? images.find((element) => element.type === "profile").file.name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="profile"
            />
          </div>
        </section>

        <section>
          <label>Home Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "home") !== -1
                ? images.find((element) => element.type === "home").file.name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="home"
            />
          </div>
        </section>

        <section>
          <label>About Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "about") !== -1
                ? images.find((element) => element.type === "about").file.name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="about"
            />
          </div>
        </section>

        <section>
          <label>Listings Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "listings") !== -1
                ? images.find((element) => element.type === "listings").file
                    .name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="listings"
            />
          </div>
        </section>

        <section>
          <label>Seller's Guide Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "sellerGuide") !==
              -1
                ? images.find((element) => element.type === "sellerGuide").file
                    .name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="sellerGuide"
            />
          </div>
        </section>

        <section>
          <label>Find My Home's Value Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "findHome") !== -1
                ? images.find((element) => element.type === "findHome").file
                    .name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="findHome"
            />
          </div>
        </section>

        <section>
          <label>Buyer's Guide Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "buyerGuide") !==
              -1
                ? images.find((element) => element.type === "buyerGuide").file
                    .name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="buyerGuide"
            />
          </div>
        </section>

        <section>
          <label>Find Property Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "findProperty") !==
              -1
                ? images.find((element) => element.type === "findProperty").file
                    .name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="findProperty"
            />
          </div>
        </section>

        <section>
          <label>Testimonials Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "testimonials") !==
              -1
                ? images.find((element) => element.type === "testimonials").file
                    .name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="testimonials"
            />
          </div>
        </section>

        <section>
          <label>Blogs Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "blogs") !== -1
                ? images.find((element) => element.type === "blogs").file.name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="blogs"
            />
          </div>
        </section>

        <section>
          <label>Contact Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex((element) => element.type === "contact") !== -1
                ? images.find((element) => element.type === "contact").file.name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="contact"
            />
          </div>
        </section>

        <section>
          <label>Neighborhoods Background</label>
          <div
            className="file-upload-wrapper"
            data-text={
              images.findIndex(
                (element) => element.type === "neighborhoods"
              ) !== -1
                ? images.find((element) => element.type === "neighborhoods")
                    .file.name
                : "Select your file !"
            }
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
              name="neighborhoods"
            />
          </div>
        </section>
        <div className="buttons">
          <button className="save" onClick={handleSubmit} disabled={loading}>
            {loading && <div className="main-loader"></div>}
            {!loading && <>Save Changes</>}
          </button>
        </div>
      </div>
    </>
  );
};

export default withRouter(Leads);
