import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyTestimonials, getTestimonialsReducer } from "../../adminSlice";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { approveTestimonialApi } from "../../../../api/testimonialsApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Testimonials = () => {
  const blogs = useSelector((state) => state.admin.testimonials);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(-1);
  const [result, setResult] = useState(-1);

  useEffect(() => {
    dispatch(getTestimonialsReducer("requested"));
  }, [dispatch]);

  const formatDate = (date) => {
    date = new Date();
    date.setTime(date);
    return date.toDateString();
  };

  const handleResult = async (element, result) => {
    try {
      setResult(result);
      setLoading(element.id);
      await approveTestimonialApi(element.id, result === 1 ? true : false);
      setResult(-1);
      setLoading(-1);
      window.location = window.location.pathname;
    } catch (err) {
      setResult(-1);
      setLoading(false);
      toast.error("Error!, try again please");
    }
  };

  const handleSelect = (type) => {
    dispatch(emptyTestimonials());
    dispatch(getTestimonialsReducer(type));
  };

  return (
    <div className="blogs">
      <ToastContainer />
      <div style={{ marginTop: "0.7rem" }}>
        <Tabs
          defaultActiveKey="requested"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          style={{ justifyContent: "space-around" }}
          onSelect={handleSelect}
        >
          <Tab eventKey="requested" title="Requested"></Tab>
          <Tab eventKey="rejected" title="Rejected"></Tab>
          <Tab eventKey="approved" title="Approved"></Tab>
        </Tabs>
      </div>
      <div className="data">
        <div className="content">
          {blogs.map((element, index) => (
            <div className="testimonial" key={index}>
              <h3>{element.title}</h3>
              <p style={{ marginBottom: "0" }}>{element.testimonial}</p>
              <footer>
                {element.name} - {formatDate(element.date)}
              </footer>
              <section className="testimonials-buttons">
                <button onClick={() => handleResult(element, 0)}>
                  {loading !== element.id || result !== 0 ? (
                    "Reject"
                  ) : (
                    <div
                      className="main-loader"
                      style={{
                        position: "relative",
                        top: "1px",
                        left: 0,
                        borderTopColor: "#fff",
                        borderBottomColor: "#fff",
                      }}
                    ></div>
                  )}
                </button>
                <button onClick={() => handleResult(element, 1)}>
                  {loading !== element.id || result !== 1 ? (
                    "Accept"
                  ) : (
                    <div
                      className="main-loader"
                      style={{
                        position: "relative",
                        top: "1px",
                        left: 0,
                        borderTopColor: "#fff",
                        borderBottomColor: "#fff",
                      }}
                    ></div>
                  )}
                </button>
              </section>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Testimonials);
