const FirstPage = ({ listing, handleChange }) => {
  return (
    <div className="first-page">
      <section>
        <label>Listing type</label>
        <select name="type" onChange={handleChange}>
          <option value="Single-Family">Single-Family</option>
          <option value="Condominium/Residential">
            Condominium/Residential
          </option>
          <option value="Apartment">Apartment</option>
          <option value="Farm/Hobby">Farm/Hobby</option>
          <option value="Commercial or industrial property">
            Commercial or industrial property
          </option>
          <option value="Land/Lot">Land/Lot</option>
        </select>
      </section>
      <section>
        <label>Listing Status</label>
        <select name="listingType" onChange={handleChange}>
          <option value="For Sale">For Sale</option>
          <option value="For Rent">For Rent</option>
        </select>
      </section>
      <section>
        <label>Listing Name</label>
        <input
          type="text"
          name="listingName"
          placeholder="Ex: Ahuntsic Cartierville"
          value={listing.listingName}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Listing Address</label>
        <input
          type="text"
          name="address"
          placeholder="Ex: 11510 Rue Poincaré H3L3L7"
          value={listing.address}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Listing Price</label>
        <input
          type="text"
          name="price"
          value={listing.price}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Liveable Surface</label>
        <input
          type="text"
          name="area"
          value={listing.area}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Rooms</label>
        <input
          type="text"
          name="rooms"
          value={listing.rooms}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Bedrooms</label>
        <input
          type="text"
          name="bedrooms"
          value={listing.bedrooms}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Bathrooms</label>
        <input
          type="text"
          name="bathrooms"
          value={listing.bathrooms}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Powder Rooms</label>
        <input
          type="text"
          name="powderRooms"
          value={listing.powderRooms}
          onChange={handleChange}
        />
      </section>
    </div>
  );
};

export default FirstPage;
