import React from "react";
import SellerContainer from "./SellerContainer";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findHomeValueReducer } from "../sellerBuyerSlice";
import { toast, ToastContainer } from "react-toastify";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import GeoCodingMap from "../../../common/components/GeoCodingMap";
import back from "../../../images/home.jpg";

const HomeValue = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sellerBuyer.isLoading);

  const [current, setCurrent] = useState(0);
  const [homeValue, setHomeValue] = useState({
    address: "",
    propertyType: "",
    year: "",
    bedroomsNum: "",
    bathroomsNum: "",
    outdoorSpaces: "",
    indoorSpaces: "",
    renovations: "",
    sellPlan: "",
    name: "",
    phone: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    address: "",
    name: "",
    phone: "",
    email: "",
    propertyType: "",
    sellPlan: "",
  });

  const handleChange = ({ currentTarget }) => {
    const errorsObj = { ...errors };
    if (errorsObj[currentTarget.name] && currentTarget.value) {
      errorsObj[currentTarget.name] = "";
      setErrors(errorsObj);
    } else if (errorsObj[currentTarget.name] === "" && !currentTarget.value) {
      errorsObj[currentTarget.name] = "error";
      setErrors(errorsObj);
    }
    setHomeValue((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const lang = getLanguageObject();
  const sellerBuyer = lang.sellerBuyer;

  const handleSubmit = () => {
    const errorsObj = { ...errors };
    let isErrors = false;
    for (let element in homeValue) {
      if (element === "name" || element === "phone" || element === "email") {
        if (!homeValue[element]) {
          errorsObj[element] = "error";
          isErrors = true;
        }
      }
    }

    if (isErrors) toast.error("please fill the required fields");
    setErrors(errorsObj);

    if (!isErrors)
      dispatch(findHomeValueReducer(homeValue))
        .unwrap()
        .then(() => {
          toast.success("Your information was sent successfully");
          setHomeValue({
            address: "",
            propertyType: "",
            year: "",
            bedroomsNum: "",
            bathroomsNum: "",
            outdoorSpaces: "",
            indoorSpaces: "",
            renovations: "",
            sellPlan: "",
            name: "",
            phone: "",
            email: "",
          });
        });
  };

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <SellerContainer
      bg={image.findHome ? image.findHome : loadedImages ? back : ""}
      bgcolor="#fff"
    >
      <ToastContainer />
      <div className="seller-buyer value">
        <header>{sellerBuyer.FindValue}</header>
        {current === 0 && (
          <div className="form value-form">
            <p className="primary">{sellerBuyer.what}</p>
            <p className="secondary">{sellerBuyer.opinion} </p>
            <section className="map"></section>
            <GeoCodingMap
              defaultValue={homeValue.address}
              error={errors.address}
              handleChange={(address) => {
                setErrors((prev) => {
                  return { ...prev, address: "" };
                });

                setHomeValue((prev) => {
                  return { ...prev, address: address };
                });
              }}
            />
            <section
              className={`input ${errors.propertyType ? "error" : ""}`}
              style={{ marginTop: "1rem" }}
            >
              <label>
                {sellerBuyer.typeOfProperty} <span>*</span>
              </label>
              <select name="propertyType" onChange={handleChange}>
                <option
                  value=""
                  selected={homeValue.propertyType === ""}
                ></option>
                <option
                  value="House"
                  selected={homeValue.propertyType === "House"}
                >
                  House
                </option>
                <option
                  value="Condo-Apartment"
                  selected={homeValue.propertyType === "Condo-Apartment"}
                >
                  Condo-Apartment
                </option>
                <option
                  value="Duplex"
                  selected={homeValue.propertyType === "Duplex"}
                >
                  Duplex
                </option>
                <option
                  value="Triplex"
                  selected={homeValue.propertyType === "Triplex"}
                >
                  Triplex
                </option>
                <option
                  value="Quadruplex"
                  selected={homeValue.propertyType === "Quadruplex"}
                >
                  Quadruplex
                </option>
                <option
                  value="Cottage"
                  selected={homeValue.propertyType === "Cottage"}
                >
                  Cottage
                </option>
                <option
                  value="Farm/Farmhouse"
                  selected={homeValue.propertyType === "Farm/Farmhouse"}
                >
                  Farm/Farmhouse
                </option>
                <option
                  value="Land"
                  selected={homeValue.propertyType === "Land"}
                >
                  Land
                </option>
                <option
                  value="Income property"
                  selected={homeValue.propertyType === "Income property"}
                >
                  Income property
                </option>
                <option
                  value="Office/Commercial"
                  selected={homeValue.propertyType === "Office/Commercial"}
                >
                  Office/Commercial
                </option>
              </select>
            </section>
            <section className="input">
              <label>{sellerBuyer.year}</label>
              <input
                type="text"
                name="year"
                value={homeValue.year}
                onChange={handleChange}
              />
            </section>
            <section className="input">
              <label>{sellerBuyer.bedroomsNum}</label>
              <select name="bedroomsNum" onChange={handleChange}>
                <option
                  value=""
                  selected={homeValue.bedroomsNum === ""}
                ></option>
                <option value="1" selected={homeValue.bedroomsNum === "1"}>
                  1
                </option>
                <option value="2" selected={homeValue.bedroomsNum === "2"}>
                  2
                </option>
                <option value="3" selected={homeValue.bedroomsNum === "3"}>
                  3
                </option>
                <option value="4" selected={homeValue.bedroomsNum === "4"}>
                  4
                </option>
                <option value="5" selected={homeValue.bedroomsNum === "5"}>
                  5
                </option>
                <option
                  value="6 and more"
                  selected={homeValue.bedroomsNum === "6 and more"}
                >
                  6 and more
                </option>
              </select>
            </section>
            <section className="input">
              <label>{sellerBuyer.bathroomsNum}</label>
              <select name="bathroomsNum" onChange={handleChange}>
                <option
                  value=""
                  selected={homeValue.bathroomsNum === ""}
                ></option>
                <option value="1" selected={homeValue.bathroomsNum === "1"}>
                  1
                </option>
                <option value="2" selected={homeValue.bathroomsNum === "2"}>
                  2
                </option>
                <option
                  value="3 and more"
                  selected={homeValue.bathroomsNum === "3 and more"}
                >
                  3 and more
                </option>
              </select>
            </section>
            <section className="input">
              <label>{sellerBuyer.outdoor}</label>
              <select name="outdoorSpaces" onChange={handleChange}>
                <option
                  value=""
                  selected={homeValue.outdoorSpaces === ""}
                ></option>
                <option value="0" selected={homeValue.outdoorSpaces === "0"}>
                  0
                </option>
                <option value="1" selected={homeValue.outdoorSpaces === "1"}>
                  1
                </option>
                <option value="2" selected={homeValue.outdoorSpaces === "2"}>
                  2
                </option>
                <option
                  value="3 and more"
                  selected={homeValue.outdoorSpaces === "3 and more"}
                >
                  3 and more
                </option>
              </select>
            </section>
            <section className="input">
              <label>{sellerBuyer.indoor}</label>
              <select name="indoorSpaces" onChange={handleChange}>
                <option
                  value=""
                  selected={homeValue.indoorSpaces === ""}
                ></option>
                <option value="0" selected={homeValue.indoorSpaces === "0"}>
                  0
                </option>
                <option value="1" selected={homeValue.indoorSpaces === "1"}>
                  1
                </option>
                <option value="2" selected={homeValue.indoorSpaces === "2"}>
                  2
                </option>
                <option
                  value="3 and more"
                  selected={homeValue.indoorSpaces === "3 and more"}
                >
                  3 and more
                </option>
              </select>
            </section>
            <section className="input">
              <label>{sellerBuyer.renovation}</label>
              <textarea
                rows={4}
                name="renovations"
                value={homeValue.renovations}
                onChange={handleChange}
              ></textarea>
            </section>
            <section className={`input ${errors.sellPlan ? "error" : ""}`}>
              <label>
                {sellerBuyer.when} <span>*</span>
              </label>
              <select name="sellPlan" onChange={handleChange}>
                <option value="" selected={homeValue.sellPlan === ""}></option>
                <option
                  value="The property is already for sale and I have a broker"
                  selected={
                    homeValue.sellPlan ===
                    "The property is already for sale and I have a broker"
                  }
                >
                  {sellerBuyer.forSale}
                </option>
                <option
                  value="The property is already for sale without a broker"
                  selected={
                    homeValue.sellPlan ===
                    "The property is already for sale without a broker"
                  }
                >
                  {sellerBuyer.noBroker}
                </option>
                <option
                  value="The sale is planned within 3 months"
                  selected={
                    homeValue.sellPlan === "The sale is planned within 3 months"
                  }
                >
                  {sellerBuyer.months3}
                </option>
                <option
                  value="The sale is planned within 6 months"
                  selected={
                    homeValue.sellPlan === "The sale is planned within 6 months"
                  }
                >
                  {sellerBuyer.months6}
                </option>
                <option
                  value="The sale is planned within the year"
                  selected={
                    homeValue.sellPlan === "The sale is planned within the year"
                  }
                >
                  {sellerBuyer.inYear}
                </option>
                <option
                  value="The sale is not yet scheduled"
                  selected={
                    homeValue.sellPlan === "The sale is not yet scheduled"
                  }
                >
                  {sellerBuyer.notYet}
                </option>
              </select>
            </section>
            <button
              className="tabs"
              onClick={() => {
                let errorsObj = { ...errors };
                let isErrors = false;
                if (homeValue.address === "") {
                  toast.error("please fill the property address field");
                  return;
                }
                for (let element in homeValue) {
                  if (
                    element === "propertyType" ||
                    element === "sellPlan" ||
                    element === "address"
                  ) {
                    if (!homeValue[element]) {
                      errorsObj[element] = "error";
                      isErrors = true;
                    }
                  }
                }
                if (isErrors) {
                  setErrors(errorsObj);
                  toast.error("please fill the required fields");
                } else setCurrent(1);
              }}
            >
              {sellerBuyer.next}{" "}
              <i
                className={`las la-angle-${
                  localStorage.getItem("lang") !== "ar" ? "right" : "left"
                }`}
              />
            </button>
          </div>
        )}
        {current === 1 && (
          <div className="form value-form">
            <p className="primary">{sellerBuyer.worth}</p>
            <p className="secondary">{sellerBuyer.evaluation}</p>

            <section className={`input ${errors.name ? "error" : ""}`}>
              <label>
                {sellerBuyer.name} <span>*</span>
              </label>
              <input
                type="text"
                name="name"
                value={homeValue.name}
                onChange={handleChange}
              />
            </section>

            <section className={`input ${errors.phone ? "error" : ""}`}>
              <label>
                {sellerBuyer.phone} <span>*</span>
              </label>
              <input
                type="text"
                name="phone"
                value={homeValue.phone}
                onChange={handleChange}
              />
            </section>

            <section className={`input ${errors.email ? "error" : ""}`}>
              <label>
                {sellerBuyer.email} <span>*</span>
              </label>
              <input
                type="text"
                name="email"
                value={homeValue.email}
                onChange={handleChange}
              />
            </section>

            <button className="tabs" onClick={() => setCurrent(0)}>
              <i
                className={`las la-angle-${
                  localStorage.getItem("lang") !== "ar" ? "left" : "right"
                }`}
              />
              {sellerBuyer.back}
            </button>
            <button onClick={handleSubmit} disabled={isLoading}>
              {isLoading && <div className="main-loader"></div>}
              {!isLoading && (
                <>
                  <i className="lar la-paper-plane" />
                  {sellerBuyer.send}
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </SellerContainer>
  );
};

export default HomeValue;
