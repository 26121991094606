import http from "./http";
import url from "../config/server.json";

const baseUrl = url.baseUrl;
export const emailSenderApi = (data, sendEmail) => {
  const route = `${baseUrl}/email`;
  data = {
    name: data.name,
    email: data.email,
    phone: data.phone,
    message: data.message,
    sendEmail: data.sendEmail,
  };
  return http.post(route, data);
};
