import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  deleteListingsApi,
  getListingsApi,
} from "../../../../api/sellerBuyerApi";
import { toast, ToastContainer } from "react-toastify";

const AllListings = () => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    const getData = async () => {
      let { data } = await getListingsApi();
      setListings(data.data);
    };

    getData();
  }, []);

  const handleDelete = async (id, index) => {
    setDeleteIndex(index);
    setIsLoading(true);
    try {
      await deleteListingsApi(id);
      setIsLoading(false);
      window.location = window.location.pathname;
    } catch (err) {
      toast.error("Error, try again");
      setDeleteIndex(-1);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="but-container"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "2.6%",
        }}
      >
        <ToastContainer />
        <button
          className="newlisting-button"
          onClick={() => (window.location = window.location.pathname + "/add")}
        >
          Add new listing
        </button>
      </div>
      {listings.map((element, index) => (
        <Container
          fluid
          className="listing"
          key={index}
          style={{
            width: "95%",
            margin: "auto",
            marginTop: 0,
            marginBottom: "1rem",
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
          }}
        >
          <Row
            className="justify-content-center"
            style={{
              width: "100%",
              marginLeft: 0,
              marginRight: 0,
              padding: "0 !important",
            }}
          >
            <Col md={4} sm={8} xs={12} className="contain">
              <div
                className="img"
                style={{ backgroundImage: `url(${element.gallery[0]?.url})` }}
              ></div>
              <section>
                <span>{element.type}</span>
                <span>{element.listingType}</span>
              </section>
            </Col>
            <Col
              md={8}
              sm={8}
              xs={12}
              className="content"
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {(!isLoading || (isLoading && deleteIndex !== index)) && (
                <i
                  className="las la-trash-alt"
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    fontSize: "1.5rem",
                    color: "#d72020",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(element.id, index)}
                />
              )}
              {isLoading && deleteIndex === index && (
                <div className="main-loader"></div>
              )}

              <Link to={`/admin/dashboard/listing/${element.id}`}>
                {element.listingName}
              </Link>
              <p>{element.address}</p>
              <p>{element.units} units</p>
              <p className="description-content">
                {element.descriptionContent}
              </p>
              <h3>${element.price}</h3>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};

export default AllListings;
