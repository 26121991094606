import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import seller from "../../../../images/seller.png";
import { getSellerGuideReducer } from "../../adminSlice";
import { withRouter } from "react-router-dom";
import CommonSbModal from "../../../../common/components/CommonSbModal";
import SingleSellerGuide from "./SingleSellerGuide";
import { Fragment } from "react";
import {
  createLeadApi,
  deleteSellerGuideApi,
} from "../../../../api/sellerBuyerApi";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";

const SellerGuide = () => {
  const dispatch = useDispatch();
  const sellerGuide = useSelector((state) => state.admin.sellerGuide);
  const [loading, setLoading] = useState(-1);
  const [deleting, setDeleting] = useState(-1);

  useEffect(() => {
    dispatch(getSellerGuideReducer());
  }, [dispatch]);

  const addToLeads = async (lead) => {
    try {
      setLoading(lead.id);
      await createLeadApi({
        name: lead.email,
        email: lead.email,
        phone: lead.phone,
        description: "",
      });
      toast.success("New lead has been added successfully");
      setLoading(-1);
    } catch (err) {
      toast.error("Lead is already added");
      setLoading(-1);
    }
  };

  const deleteRequest = async (lead) => {
    try {
      setDeleting(lead.id);
      await deleteSellerGuideApi(lead.id);
      window.location = window.location.pathname;
      setDeleting(-1);
    } catch (err) {
      toast.error("Error!, try again please");
      setDeleting(-1);
    }
  };

  return (
    <div className="sb-admin">
      <ToastContainer />
      <a href="https://www.oaciq.com/uploads/ckeditor/attachments/402/sellers-guide.pdf">
        <img src={seller} alt="seller-guide" />
      </a>
      <div className="data">
        <h4>Seller's guide requests</h4>
        {sellerGuide.map((element, index) => (
          <Fragment key={index}>
            <div className="single-data" key={index}>
              <section>
                <i className="las la-user icon" />
                {element.name}
              </section>
              <section>
                <i className="las la-envelope icon email" />
                {element.email}
              </section>

              <CommonSbModal heading="Seller's guide request data">
                <p>Open</p>
                <SingleSellerGuide element={element} />
              </CommonSbModal>

              <section className="edited">
                <button onClick={() => deleteRequest(element)}>
                  {deleting !== element.id ? (
                    "Delete request"
                  ) : (
                    <div className="main-loader"></div>
                  )}
                </button>
                <button onClick={() => addToLeads(element)}>
                  {loading !== element.id ? (
                    "Add to leads"
                  ) : (
                    <div className="main-loader"></div>
                  )}
                </button>
              </section>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default withRouter(SellerGuide);
