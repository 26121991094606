import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JoulnarCard from "../../../common/components/JoulnarCard";

import "../../seller and buyer/seller.css";

const BlogsContainer = ({ children, bg, bgcolor }) => {
  return (
    <>
      <div
        className="upper-bg"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center !important",
        }}
      ></div>
      <Container fluid className="bs-container">
        <Row className="justify-content-center ">
          <Col
            md={7}
            sm={9}
            xs={11}
            className="bs-body"
            style={{ background: bgcolor, padding: 0 }}
          >
            {children}
          </Col>
          <Col md={3} sm={9} xs={11}>
            <JoulnarCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogsContainer;
