import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { sendEmailReducer, sendMessageReducer } from "../../adminSlice";
import { withRouter } from "react-router-dom";

const EditBlog = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.blodLoading);
  const item = props.history.location.state;

  const [blog, setBlog] = useState({
    id: item.element.id,
    name: item.element.name,
    email: item.element.email,
    phone: item.element.phone,
    subject: "",
    content: "",
  });

  const handleChange = ({ currentTarget }) => {
    setBlog((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = () => {
    let data = { reciever: parseInt(item.element.id), message: blog.content };
    if (item.email)
      dispatch(sendEmailReducer({ ...data, subject: blog.subject }))
        .unwrap()
        .then((_) => {
          toast.success("The email has been sent successfully");
        });
    else
      dispatch(sendMessageReducer(data))
        .unwrap()
        .then((_) => {
          toast.success("The message has been sent successfully");
        });
  };

  return (
    <div className="single-blog create-blog">
      <ToastContainer />
      <h4>Send {item.email ? "email" : "SMS"}</h4>
      <input
        type="text"
        name="name"
        value={blog.name}
        placeholder="Lead Name"
        onChange={handleChange}
        disabled
      />
      {item.email && (
        <>
          <input
            type="text"
            name="email"
            value={blog.email}
            placeholder="Lead Email"
            onChange={handleChange}
            disabled
          />
          <input
            type="text"
            name="subject"
            value={blog.subject}
            placeholder="Email Subject"
            onChange={handleChange}
          />
        </>
      )}
      {!item.email && (
        <input
          type="text"
          name="phone"
          value={blog.phone}
          placeholder="Lead Phone"
          onChange={handleChange}
          disabled
        />
      )}
      <textarea
        placeholder="Content"
        rows={10}
        name="content"
        onChange={handleChange}
        style={{ marginTop: "1rem" }}
        value={blog.content}
      ></textarea>

      <div>
        <button onClick={handleSubmit} disabled={isLoading} className="save">
          {isLoading && <div className="main-loader"></div>}
          {!isLoading && <>Send</>}
        </button>
      </div>
    </div>
  );
};

export default withRouter(EditBlog);
