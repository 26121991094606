import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const ControlledCarousel = ({ items }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const getDate = (date) => {
    let newDate = new Date(date);
    return newDate.toDateString();
  };

  const getItems = () => {
    let arr = [];
    for (let i = 0; i < items.length; i++) {
      arr.push({
        first: items[i],
        second: items[i + 1],
      });
      i++;
    }
    console.log(arr);
    return arr;
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
      {getItems().map((element, i) => (
        <Carousel.Item key={i} className="testimonial-slider">
          <div className="flex-container">
            <div className="single-testimonial">
              <p>{element.first?.title}</p>
              <p>{element.first?.testimonial}</p>
              <p>
                {element.first.name} - {getDate(element.first.date)}
              </p>
            </div>
            {element.second && (
              <div className="single-testimonial">
                <p>{element.second?.title}</p>
                <p>{element.second?.testimonial}</p>
                <p>
                  {element.second?.name} - {getDate(element.second?.date)}
                </p>
              </div>
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ControlledCarousel;
