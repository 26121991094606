import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { createLeadReducer } from "../../adminSlice";

const CreateLead = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.blodLoading);

  const [blog, setBlog] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleChange = ({ currentTarget }) => {
    setBlog((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = () => {
    dispatch(createLeadReducer(blog))
      .unwrap()
      .then((_) => {
        setBlog({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
        toast.success("The lead has been added successfully");
      })
      .catch((err) => toast.error("Lead is already added"));
  };

  return (
    <div className="single-blog create-blog">
      <ToastContainer />
      <h4>Add a lead</h4>
      <input
        type="text"
        name="name"
        value={blog.name}
        placeholder="Lead Name"
        onChange={handleChange}
      />
      <input
        type="text"
        name="email"
        value={blog.email}
        placeholder="Lead Email"
        onChange={handleChange}
      />
      <input
        type="text"
        name="phone"
        value={blog.phone}
        placeholder="Lead Phone"
        onChange={handleChange}
      />
      <textarea
        placeholder="Description"
        rows={10}
        name="description"
        onChange={handleChange}
        style={{ marginTop: "1rem" }}
        value={blog.description}
      ></textarea>

      <div>
        <button onClick={handleSubmit} disabled={isLoading} className="save">
          {isLoading && <div className="main-loader"></div>}
          {!isLoading && <>Send</>}
        </button>
      </div>
    </div>
  );
};

export default CreateLead;
