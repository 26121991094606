import HomeContainer from "./HomeContainer";
import ControlledCarousel from "../../../common/components/ControlledCarousel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTestimonialReducer } from "../../testimonials/testimonialSlice";
import "../home.css";
import { useState } from "react";
import { getListingsApi } from "../../../api/sellerBuyerApi";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import back from "../../../images/home.jpg";

const Home = () => {
  const disaptch = useDispatch();
  const testimonials = useSelector((state) => state.testimonials.testimonials);
  const image = useSelector((state) => state.admin.images);
  const [listings, setList] = useState([]);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  useEffect(() => {
    disaptch(getTestimonialReducer());
    const getData = async () => {
      let { data } = await getListingsApi();
      setList(data.data);
    };

    getData();
  }, [disaptch]);

  return (
    <HomeContainer bg={image.home ? image.home : loadedImages ? back : ""}>
      <div className="home-page">
        {testimonials?.length > 0 && (
          <ControlledCarousel items={testimonials} />
        )}
        <div className="all-listings">
          {listings.map((listing, index) => (
            <Container
              fluid
              className="listing"
              key={index}
              style={{ overflow: "hidden", margin: "2rem auto" }}
            >
              <Row className="justify-content-center">
                <Col md={4} sm={8} xs={12} className="contain">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${listing.gallery[0]?.url})`,
                    }}
                  ></div>
                  <section>
                    <span>{listing.type}</span>
                    <span>{listing.listingType}</span>
                  </section>
                </Col>
                <Col
                  md={8}
                  sm={8}
                  xs={12}
                  className="content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Link to={`/listings/${listing.id}`}>
                    {listing.listingName}
                  </Link>
                  <p>{listing.address}</p>
                  <p>{listing.units} units</p>
                  <p className="description-content">
                    {listing.descriptionContent}
                  </p>
                  <h3>${listing.price}</h3>
                </Col>
              </Row>
            </Container>
          ))}
        </div>
      </div>
    </HomeContainer>
  );
};

export default Home;
