import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JoulnarCard from "../../../common/components/JoulnarCard";
import { useState } from "react";
import { emailSenderApi } from "../../../api/emailApi";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import back from "../../../images/home.jpg";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);

  const lang = getLanguageObject().card;
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    phone: "",
    message: lang.hello,
  });
  const [sendEmail, setSendEmail] = useState(false);

  const handleChange = ({ currentTarget }) => {
    setEmailData((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await emailSenderApi(emailData, sendEmail);
      setIsLoading(false);
      toast.success("Your information was sent successfully");
    } catch (err) {
      setIsLoading(false);
      toast.error("Error ! try again");
    }
  };

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <>
      <ToastContainer />
      <div
        className="upper-bg"
        style={{
          backgroundImage: `url(${
            image.contact ? image.contact : loadedImages ? back : ""
          })`,
        }}
      ></div>
      <Container fluid className="bs-container">
        <Row className="justify-content-center ">
          <Col
            md={7}
            sm={9}
            xs={11}
            className="bs-body"
            style={{
              backgroundColor: "#fff",
              padding: 0,
              borderRadius: "0.25rem",
              border: "1px solid rgba(0,0,0,0.125)",
            }}
          >
            <div className="email-body" style={{ padding: "1.25rem" }}>
              <h3 style={{ color: "#e84042", marginBottom: "1rem" }}>
                {lang.contact}
              </h3>
              <input
                type="text"
                name="name"
                value={emailData.name}
                onChange={handleChange}
                placeholder={lang.name}
              />
              <input
                type="email"
                placeholder={lang.email}
                name="email"
                value={emailData.email}
                onChange={handleChange}
              />
              <input
                type="phone"
                placeholder={lang.phone}
                name="phone"
                value={emailData.phone}
                onChange={handleChange}
              />
              <textarea
                rows={10}
                name="message"
                value={emailData.message}
                onChange={handleChange}
              ></textarea>
              <div className="email-send">
                <input
                  type="checkbox"
                  className="email-send"
                  onChange={() => setSendEmail(!sendEmail)}
                />{" "}
                {lang.emailSend}
              </div>
              <button onClick={handleSubmit} disabled={isLoading}>
                {isLoading && <div className="main-loader"></div>}
                {!isLoading && (
                  <>
                    <i className="lar la-paper-plane" />
                    {lang.submit}
                  </>
                )}
              </button>
            </div>
          </Col>
          <Col md={3} sm={9} xs={11}>
            <JoulnarCard hideEmail={true} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
