const SecondPage = ({ listing, handleChange }) => {
  return (
    <div className="second-page">
      <section>
        <label>Description header</label>
        <input
          type="text"
          name="descriptionHeader"
          placeholder="Ex: One-and-a-half-storey house - 11510 Rue Poincaré"
          value={listing.descriptionHeader}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Description Content</label>
        <textarea
          type="text"
          name="descriptionContent"
          rows={5}
          value={listing.descriptionContent}
          onChange={handleChange}
        ></textarea>
      </section>
      <section>
        <label>Inclusion</label>
        <textarea
          type="text"
          name="inclusion"
          rows={5}
          value={listing.inclusion}
          onChange={handleChange}
        ></textarea>
      </section>
      <section>
        <label>Exclusion</label>
        <textarea
          type="text"
          name="exclusion"
          rows={5}
          value={listing.exclusion}
          onChange={handleChange}
        ></textarea>
      </section>
    </div>
  );
};

export default SecondPage;
