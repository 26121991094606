import React from "react";
import SellerContainer from "./SellerContainer";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findPropertyReducer } from "../sellerBuyerSlice";
import { toast, ToastContainer } from "react-toastify";
import { getLanguageObject } from "../../../common/utils/languageDetector";
import back from "../../../images/home.jpg";

const FindProperty = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.sellerBuyer.isLoading);

  const [findProperty, setFindProperty] = useState({
    name: "",
    phone: "",
    email: "",
    propertyType: "",
    bedroomsNeeded: "",
    bathroomsNeeded: "",
    budget: "",
    whenToBuy: "",
    neighbourhood: "",
    notes: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    propertyType: "",
    bedroomsNeeded: "",
    bathroomsNeeded: "",
    budget: "",
    whenToBuy: "",
    neighbourhood: "",
  });

  const handleChange = ({ currentTarget }) => {
    const errorsObj = { ...errors };
    if (errorsObj[currentTarget.name] && currentTarget.value) {
      errorsObj[currentTarget.name] = "";
      setErrors(errorsObj);
    } else if (errorsObj[currentTarget.name] === "" && !currentTarget.value) {
      errorsObj[currentTarget.name] = "error";
      setErrors(errorsObj);
    }
    setFindProperty((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = () => {
    const errorsObj = { ...errors };
    let isErrors = false;
    for (let element in findProperty) {
      if (
        element === "name" ||
        element === "phone" ||
        element === "email" ||
        element === "propertyType" ||
        element === "bedroomsNeeded" ||
        element === "bathroomsNeeded" ||
        element === "budget" ||
        element === "whenToBuy" ||
        element === "neighbourhood"
      ) {
        if (!findProperty[element]) {
          errorsObj[element] = "error";
          isErrors = true;
        }
      }
    }

    if (isErrors) toast.error("please fill the required fields");
    setErrors(errorsObj);

    if (!isErrors)
      dispatch(findPropertyReducer(findProperty))
        .unwrap()
        .then(() => {
          toast.success("Your information was sent successfully");
          setFindProperty({
            name: "",
            phone: "",
            email: "",
            propertyType: "",
            bedroomsNeeded: "",
            bathroomsNeeded: "",
            budget: "",
            whenToBuy: "",
            neighbourhood: "",
            notes: "",
          });
        });
  };

  const { sellerBuyer } = getLanguageObject();

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <SellerContainer
      bg={image.findProperty ? image.findProperty : loadedImages ? back : ""}
      bgcolor="#fff"
    >
      <ToastContainer />
      <div className="seller-buyer value">
        <header>{sellerBuyer.findProperty}</header>
        <div className="form value-form">
          <section className={`input ${errors.name ? "error" : ""}`}>
            <label>
              {sellerBuyer.name} <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              value={findProperty.name}
              onChange={handleChange}
            />
          </section>

          <section className={`input ${errors.phone ? "error" : ""}`}>
            <label>
              {sellerBuyer.phone} <span>*</span>
            </label>
            <input
              type="text"
              name="phone"
              value={findProperty.phone}
              onChange={handleChange}
            />
          </section>

          <section className={`input ${errors.email ? "error" : ""}`}>
            <label>
              {sellerBuyer.email} <span>*</span>
            </label>
            <input
              type="text"
              name="email"
              value={findProperty.email}
              onChange={handleChange}
            />
          </section>

          <section className={`input ${errors.propertyType ? "error" : ""}`}>
            <label>
              {sellerBuyer.typeOfProperty} <span>*</span>
            </label>
            <select name="propertyType" onChange={handleChange}>
              <option value=""></option>
              <option value="Single-Family Home">Single-Family Home</option>
              <option value="Condo">Condo</option>
              <option value="Loft/Studio">Loft/Studio</option>
              <option value="Intergenerational">Intergenerational</option>
              <option value="Multi-Units">Multi-Units</option>
              <option value="Farm">Farm</option>
              <option value="Cottage">Cottage</option>
              <option value="Land">Land</option>
              <option value="Income property">Income property</option>
              <option value="Offices/Commercial">Offices/Commercial</option>
              <option value="Other">Other</option>
            </select>
          </section>

          <section className={`input ${errors.bedroomsNeeded ? "error" : ""}`}>
            <label>
              {sellerBuyer.bedroomsNeeded} <span>*</span>
            </label>
            <select name="bedroomsNeeded" onChange={handleChange}>
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4 and more">4 and more</option>
            </select>
          </section>

          <section className={`input ${errors.bathroomsNeeded ? "error" : ""}`}>
            <label>
              {sellerBuyer.bathroomsNeeded} <span>*</span>
            </label>
            <select name="bathroomsNeeded" onChange={handleChange}>
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4 and more">4 and more</option>
            </select>
          </section>

          <section className={`input ${errors.budget ? "error" : ""}`}>
            <label>
              {sellerBuyer.budget} <span>*</span>
            </label>
            <select name="budget" onChange={handleChange}>
              <option value=""></option>
              <option value="$100k - $199k">$100k - $199k</option>
              <option value="$200k - $299k">$200k - $299k</option>
              <option value="$300k - $399k">$300k - $399k</option>
              <option value="$400k - $499k">$400k - $499k</option>
              <option value="$500k - $900k">$500k - $900k</option>
              <option value="$1M">$1M</option>
            </select>
          </section>

          <section className={`input ${errors.whenToBuy ? "error" : ""}`}>
            <label>
              {sellerBuyer.whenBuy} <span>*</span>
            </label>
            <select name="whenToBuy" onChange={handleChange}>
              <option value=""></option>
              <option value="0 to 3 months">{sellerBuyer.months03}</option>
              <option value="3 to 6 months">{sellerBuyer.months36}</option>
              <option value="6 to 12 months">{sellerBuyer.months612}</option>
              <option value="More than 12 months">
                {sellerBuyer.months12}
              </option>
            </select>
          </section>

          <section className={`input ${errors.neighbourhood ? "error" : ""}`}>
            <label>
              {sellerBuyer.inWhich} <span>*</span>
            </label>
            <input
              type="text"
              name="neighbourhood"
              value={findProperty.neighbourhood}
              onChange={handleChange}
            />
            <p className="description">ex. Westmount, Ville-Marie, Old Port</p>
          </section>

          <section className="input">
            <label>{sellerBuyer.comNotes}</label>
            <textarea
              rows={4}
              name="notes"
              value={findProperty.notes}
              onChange={handleChange}
            ></textarea>
          </section>

          <button onClick={handleSubmit} disabled={isLoading}>
            {isLoading && <div className="main-loader"></div>}
            {!isLoading && (
              <>
                <i className="lar la-paper-plane" />
                {sellerBuyer.send}
              </>
            )}
          </button>
        </div>
      </div>
    </SellerContainer>
  );
};

export default FindProperty;
