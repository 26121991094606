import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createBlogApi,
  createLeadApi,
  createNeighborhoodsApi,
  deleteBlogApi,
  deleteLeadApi,
  deleteNeighborhoodApi,
  editBlogApi,
  editLeadApi,
  editNeighborhoodApi,
  getAdminNeighborhoodsApi,
  getBlogsApi,
  getBuyerGuideApi,
  getFindPropbertyApi,
  getHomeValueApi,
  getImagesApi,
  getLeadsApi,
  getNeighborhoodsApi,
  getPublishedBlogsApi,
  getSellerGuideApi,
  sendEmailApi,
  sendSmsApi,
} from "../../api/sellerBuyerApi";
import { getTestimonialApi } from "../../api/testimonialsApi";

export const getSellerGuideReducer = createAsyncThunk(
  "admin/getSellerGuide",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getSellerGuideApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getBuyerGuideReducer = createAsyncThunk(
  "admin/getBuyerGuide",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getBuyerGuideApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getHomeValueReducer = createAsyncThunk(
  "admin/getHomeValue",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getHomeValueApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const findPropertyReducer = createAsyncThunk(
  "admin/findProperty",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getFindPropbertyApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const createBlogReducer = createAsyncThunk(
  "admin/createBlog",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await createBlogApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getBlogsReducer = createAsyncThunk(
  "admin/getBlogs",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getBlogsApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getTestimonialsReducer = createAsyncThunk(
  "admin/getTestimonials",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getTestimonialApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getPublishedBlogsReducer = createAsyncThunk(
  "admin/getPublishedBlogs",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getPublishedBlogsApi();
      const sortedBlogs = data.data.sort((a, b) => {
        return a.data > b.date ? 1 : -1;
      });
      return sortedBlogs;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const deleteBlogReducer = createAsyncThunk(
  "admin/deleteBlog",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await deleteBlogApi(args);
      return true;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const editBlogReducer = createAsyncThunk(
  "admin/editBlog",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await editBlogApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const createNeighborhoodReducer = createAsyncThunk(
  "admin/createNeighborhood",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await createNeighborhoodsApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getNeighborhoodsReducer = createAsyncThunk(
  "admin/getNeighborhoods",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getNeighborhoodsApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getAdminNeighborhoodsReducer = createAsyncThunk(
  "admin/getAdminNeighborhoods",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getAdminNeighborhoodsApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const deleteNeighborhoodReducer = createAsyncThunk(
  "admin/deleteNeighborhood",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await deleteNeighborhoodApi(args);
      return true;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const editNeighborhoodsReducer = createAsyncThunk(
  "admin/editNeighborhoods",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await editNeighborhoodApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const createLeadReducer = createAsyncThunk(
  "admin/createLead",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await createLeadApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getLeadsReducer = createAsyncThunk(
  "admin/getLeads",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getLeadsApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const deleteLeadReducer = createAsyncThunk(
  "admin/deleteLead",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await deleteLeadApi(args);
      return true;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const editLeadReducer = createAsyncThunk(
  "admin/editLead",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await editLeadApi(args);
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const sendMessageReducer = createAsyncThunk(
  "admin/sendMessage",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await sendSmsApi(args);
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const sendEmailReducer = createAsyncThunk(
  "admin/sendEmail",
  async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      await sendEmailApi(args);
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

export const getImagesReducer = createAsyncThunk(
  "admin/getImages",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let { data } = await getImagesApi();
      return data.data;
    } catch (err) {
      return rejectWithValue("Adding Failed");
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    sellerGuide: [],
    buyerGuide: [],
    homeValue: [],
    findProperty: [],
    blodLoading: false,
    blogs: [],
    neigs: [],
    leads: [],
    published: [],
    testimonials: [],
    neighs: [],
    adminNeigs: [],
    images: {},
    loadedImages: false,
  },
  extraReducers: {
    [getSellerGuideReducer.fulfilled]: (state, action) => {
      state.sellerGuide = action.payload;
    },
    [getBuyerGuideReducer.fulfilled]: (state, action) => {
      state.buyerGuide = action.payload;
    },
    [getHomeValueReducer.fulfilled]: (state, action) => {
      state.homeValue = action.payload;
    },
    [findPropertyReducer.fulfilled]: (state, action) => {
      state.findProperty = action.payload;
    },
    [createBlogReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [createBlogReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [createBlogReducer.rejected]: (state) => {
      state.blodLoading = false;
    },
    [getBlogsReducer.fulfilled]: (state, action) => {
      state.blogs = action.payload;
    },
    [getPublishedBlogsReducer.fulfilled]: (state, action) => {
      state.published = action.payload;
    },

    [getTestimonialsReducer.fulfilled]: (state, action) => {
      state.testimonials = action.payload;
    },

    [deleteBlogReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [deleteBlogReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [deleteBlogReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [editBlogReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [editBlogReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [editBlogReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [createNeighborhoodReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [createNeighborhoodReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [createNeighborhoodReducer.rejected]: (state) => {
      state.blodLoading = false;
    },
    [getNeighborhoodsReducer.fulfilled]: (state, action) => {
      state.neigs = action.payload;
    },

    [getAdminNeighborhoodsReducer.fulfilled]: (state, action) => {
      state.adminNeigs = action.payload;
    },

    [deleteNeighborhoodReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [deleteNeighborhoodReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [deleteNeighborhoodReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [editNeighborhoodsReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [editNeighborhoodsReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [editNeighborhoodsReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [createLeadReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [createLeadReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [createLeadReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [getLeadsReducer.fulfilled]: (state, action) => {
      state.leads = action.payload;
    },

    [deleteLeadReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [deleteLeadReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [deleteLeadReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [editLeadReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [editLeadReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [editLeadReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [sendMessageReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [sendMessageReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [sendMessageReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [sendEmailReducer.pending]: (state) => {
      state.blodLoading = true;
    },
    [sendEmailReducer.fulfilled]: (state) => {
      state.blodLoading = false;
    },
    [sendEmailReducer.rejected]: (state) => {
      state.blodLoading = false;
    },

    [getImagesReducer.fulfilled]: (state, action) => {
      let newImages = {};
      for (let element of action.payload) {
        newImages[element.type] = element.url;
      }
      state.images = newImages;
      state.loadedImages = true;
    },
  },

  reducers: {
    emptyBlogs: (state) => {
      state.blogs = [];
    },
    emptyTestimonials: (state) => {
      state.testimonials = [];
    },
    emptyNeigs: (state) => {
      state.adminNeigs = [];
    },
  },
});

export const { emptyBlogs, emptyTestimonials, emptyNeigs } = adminSlice.actions;
export default adminSlice.reducer;
