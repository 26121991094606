import http from "./http";
import url from "../config/server.json";

const baseUrl = url.baseUrl;

export const loginApi = (data) => {
  const route = `${baseUrl}/login`;
  data = {
    email: data.email,
    password: data.password,
  };
  return http.post(route, data);
};

export const changeAdminData = (data, change, email) => {
  const route = `${baseUrl}/admin`;
  if (change && email)
    data = {
      newEmail: data.email,
      password: data.password,
      newPassword: data.newPassword,
    };
  else if (change && !email)
    data = {
      password: data.password,
      newPassword: data.newPassword,
    };
  else
    data = {
      newEmail: data.email,
      password: data.password,
      newPassword: data.newPassword,
    };
  return http.patch(route, data, {
    headers: { Authorization: localStorage.getItem("token") },
  });
};
