import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { changeAdminData } from "../../../../api/authApis";
import loginImg from "../../../../images/login.svg";

const Profile = () => {
  const [login, setLogin] = useState({
    email: "",
    password: "",
    newPassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [email, setEmail] = useState(false);

  const handleChange = ({ currentTarget }) => {
    setLogin((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = async () => {
    try {
      if (!email && !change) return;
      if (email && !login.email) {
        return toast.error("Fill the new email please");
      }
      if (change && (!login.password || !login.newPassword)) {
        return toast.error("Fill the old and the new passwords please");
      }
      setIsLoading(true);
      let { data } = await changeAdminData(login, change, email);
      setIsLoading(false);
      if (change && !data.data.changePasswordresult) {
        return toast.error("wrong old password");
      }
      toast.success("Data has been updated successfully");
      setLogin({
        email: "",
        password: "",
        newPassword: "",
      });
      setChange(false);
      setEmail(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container profile-container">
      <ToastContainer />
      <div className="login">
        <img src={loginImg} alt="login" />
        <label>
          <input
            type="checkbox"
            onChange={() => setEmail(!email)}
            checked={email}
          />
          Change Email ?
        </label>
        {email && (
          <input
            type="email"
            name="email"
            placeholder="New Email"
            value={login.email}
            onChange={handleChange}
          />
        )}
        <label>
          <input
            type="checkbox"
            onChange={() => setChange(!change)}
            checked={change}
          />
          Change Password ?
        </label>
        {change && (
          <>
            <input
              type="password"
              name="password"
              placeholder="Old password"
              value={login.password}
              onChange={handleChange}
            />
            <input
              type="password"
              name="newPassword"
              placeholder="New password"
              value={login.newPassword}
              onChange={handleChange}
            />
          </>
        )}
        <button onClick={handleSubmit} disabled={isLoading}>
          {isLoading && <div className="main-loader"></div>}
          {!isLoading && <>Send</>}
        </button>
      </div>
    </div>
  );
};

export default Profile;
