import { configureStore } from "@reduxjs/toolkit";
import sellerBuyerReducer from "../features/seller and buyer/sellerBuyerSlice";
import testimonialsReducer from "../features/testimonials/testimonialSlice";
import adminReducer from "../features/admin/adminSlice";

const store = configureStore({
  reducer: {
    sellerBuyer: sellerBuyerReducer,
    testimonials: testimonialsReducer,
    admin: adminReducer,
  },
});

export default store;
