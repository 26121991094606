import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../features/admin/components/dashboard/Dashboard";
import Login from "../features/authentication/components/Login";
import Logout from "../features/authentication/components/Logout";
import App from "./App";

const CommonApp = () => {
  return (
    <Switch>
      {localStorage.getItem("token") !== null && (
        <Route path="/admin/dashboard" component={Dashboard}></Route>
      )}
      <Route path="/admin" component={Login}></Route>
      <Route path="/logout" component={Logout}></Route>
      <Route path="/" component={App}></Route>
      <Redirect to="/"></Redirect>
    </Switch>
  );
};

export default CommonApp;
