import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNeighborhoodReducer,
  emptyNeigs,
  getAdminNeighborhoodsReducer,
} from "../../adminSlice";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const Neighbourhoods = (props) => {
  const blogs = useSelector((state) => state.admin.adminNeigs);
  const isLoading = useSelector((state) => state.admin.blodLoading);
  const dispatch = useDispatch();
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const handleClick = (element) => {
    props.history.push({
      pathname: "/admin/dashboard/neighborhoods/" + 1,
      state: { element },
    });
  };

  useEffect(() => {
    dispatch(emptyNeigs());

    dispatch(getAdminNeighborhoodsReducer(true));
  }, [dispatch]);

  const handleDelete = (element, index) => {
    setDeleteIndex(index);
    dispatch(deleteNeighborhoodReducer(element.id))
      .unwrap()
      .then((_) => {
        setDeleteIndex(-1);
        window.location = window.location.pathname;
      });
  };

  const getDate = (date) => {
    let returnDate = new Date();
    returnDate.setTime(date);
    return returnDate.toDateString();
  };

  const getAllBody = (element) => {
    const json = JSON.parse(JSON.parse(element));
    let string = "";
    for (let i = 0; i < json.length; i++) {
      string +=
        json[i].header[
          json[i].header.en !== ""
            ? "en"
            : json[i].header.fr !== ""
            ? "fr"
            : "ar"
        ] +
        " " +
        json[i].body[
          json[i].body.en !== "" ? "en" : json[i].body.fr !== "" ? "fr" : "ar"
        ] +
        " ";
    }

    while (string.indexOf("`") !== -1) {
      const startIndex = string.indexOf("`");

      string = string.replace("`", "");
      const endIndex = string.indexOf("`");
      string = string.replace("`", "");

      string = string.replace(
        string.substring(startIndex, endIndex),
        `<a href=${string.substring(
          startIndex,
          endIndex
        )} rel="noreferrer" target="_blank">${string.substring(
          startIndex,
          endIndex
        )}</a>`
      );
    }

    return string;
  };

  const handleForward = () => {
    props.history.push({
      pathname: "/admin/dashboard/create-neighborhood",
    });
  };

  const handleModify = (element) => {
    props.history.push({
      pathname: "/admin/dashboard/edit-neighborhood",
      state: element,
    });
  };

  const handleSelect = (type) => {
    dispatch(emptyNeigs());
    dispatch(getAdminNeighborhoodsReducer(type === "draft" ? true : false));
  };

  return (
    <div className="blogs">
      <section>
        <button onClick={handleForward}>Add new neighborhood</button>
      </section>
      <div style={{ marginTop: "0.7rem" }}>
        <Tabs
          defaultActiveKey="draft"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          style={{ justifyContent: "space-around" }}
          onSelect={handleSelect}
        >
          <Tab eventKey="draft" title="Draft"></Tab>
          <Tab eventKey="published" title="Published"></Tab>
        </Tabs>
      </div>
      <div className="data">
        <div className="content">
          {blogs.map((element, index) => (
            <div className="single-data" key={index}>
              <div
                style={{ backgroundImage: `url(${element.imageUrl})` }}
              ></div>
              <section>
                {(!isLoading || (isLoading && deleteIndex !== index)) && (
                  <i
                    className="las la-trash-alt icon trash"
                    onClick={() => handleDelete(element, index)}
                  />
                )}
                {isLoading && deleteIndex === index && (
                  <div className="main-loader"></div>
                )}
                <p>{getDate(element.date)}</p>
                <h4>
                  {
                    JSON.parse(element.subject)[
                      JSON.parse(element.subject).en !== ""
                        ? "en"
                        : JSON.parse(element.subject).fr !== ""
                        ? "fr"
                        : "ar"
                    ]
                  }
                </h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getAllBody(element.body),
                  }}
                ></p>
                <section>
                  <button onClick={() => handleClick(element)}>
                    Read More
                  </button>
                  <div
                    style={{
                      marginTop: 0,
                      background: "#e84042",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      color: "#fff",
                      width: "fit-content",
                      top: "1rem",
                      right: "1rem",
                      padding: "0.4rem 1rem",
                      borderRadius: "5px",
                      cursor: "pointer",
                      marginLeft: "1rem",
                    }}
                    onClick={() => handleModify({ element })}
                  >
                    <i
                      className="las la-edit icon edit-con"
                      style={{
                        cursor: "pointer",
                        fontSize: "1.3rem",
                        marginRight: "0.3rem",
                      }}
                    />
                    Modify
                  </div>
                </section>
              </section>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Neighbourhoods);
