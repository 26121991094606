const ThirdPage = ({ listing, handleChange }) => {
  return (
    <div className="third-page">
      <h5>General information</h5>
      <section>
        <label>Reference</label>
        <input
          type="text"
          name="reference"
          value={listing.reference}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Unit(s)</label>
        <input
          type="text"
          name="units"
          value={listing.units}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Year Of Construction</label>
        <input
          type="text"
          name="yearOfConstruction"
          value={listing.yearOfConstruction}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Municipal Tax</label>
        <input
          type="text"
          name="municipalTax"
          value={listing.municipalTax}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>School Tax</label>
        <input
          type="text"
          name="schoolTax"
          value={listing.schoolTax}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Sewage System</label>
        <input
          type="text"
          name="sewageSystem"
          value={listing.sewageSystem}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Water Supply</label>
        <input
          type="text"
          name="waterSupply"
          value={listing.waterSupply}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Foundation</label>
        <input
          type="text"
          name="foundation"
          value={listing.foundation}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Roofing</label>
        <input
          type="text"
          name="roofing"
          value={listing.roofing}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Siding</label>
        <input
          type="text"
          name="siding"
          value={listing.siding}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Windows</label>
        <input
          type="text"
          name="windows"
          value={listing.windows}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Window Type</label>
        <input
          type="text"
          name="windowType"
          value={listing.windowType}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Heating Energy</label>
        <input
          type="text"
          name="heatingEnergy"
          value={listing.heatingEnergy}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Heating System</label>
        <input
          type="text"
          name="heatingSystem"
          value={listing.heatingSystem}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Bathroom / Washroom</label>
        <input
          type="text"
          name="bathroomWashroom"
          value={listing.bathroomWashroom}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Hearth Stove</label>
        <input
          type="text"
          name="hearthStove"
          value={listing.hearthStove}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Cupboard</label>
        <input
          type="text"
          name="cupboard"
          value={listing.cupboard}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Rental Appliance</label>
        <input
          type="text"
          name="rentalAppliance"
          value={listing.rentalAppliance}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>View</label>
        <input
          type="text"
          name="view"
          value={listing.view}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Equipment Available</label>
        <input
          type="text"
          name="equipmentAvailable"
          value={listing.equipmentAvailable}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Pool</label>
        <input
          type="text"
          name="pool"
          value={listing.pool}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Parking</label>
        <input
          type="text"
          name="parking"
          value={listing.parking}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Garage</label>
        <input
          type="text"
          name="garage"
          value={listing.garage}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Landscaping</label>
        <input
          type="text"
          name="landscaping"
          value={listing.landscaping}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Proximity</label>
        <input
          type="text"
          name="proximity"
          value={listing.proximity}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Zoning</label>
        <input
          type="text"
          name="zoning"
          value={listing.zoning}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Land</label>
        <input
          type="text"
          name="land"
          value={listing.land}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Type Of Property</label>
        <input
          type="text"
          name="typeOfProperty"
          value={listing.typeOfProperty}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Type Of Building</label>
        <input
          type="text"
          name="typeOfBuilding"
          value={listing.typeOfBuilding}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Building Dimensions</label>
        <input
          type="text"
          name="buildingDimensions"
          value={listing.buildingDimensions}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Land Dimensions</label>
        <input
          type="text"
          name="landDimensions"
          value={listing.landDimensions}
          onChange={handleChange}
        />
      </section>
      <section>
        <label>Occupation Period</label>
        <input
          type="text"
          name="occupationPeriod"
          value={listing.occupationPeriod}
          onChange={handleChange}
        />
      </section>
    </div>
  );
};

export default ThirdPage;
