import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { createNeighborhoodReducer } from "../../adminSlice";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const CreateNeighbourhood = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.blodLoading);
  const [publish, setPublish] = useState(-1);
  const [selectedLang, setSelectedLang] = useState("en");

  const [blog, setBlog] = useState({
    subject: {
      ar: "",
      en: "",
      fr: "",
    },
    counterArray: [
      {
        header: {
          ar: "",
          en: "",
          fr: "",
        },
        body: {
          ar: "",
          en: "",
          fr: "",
        },
      },
    ],
    file: "",
  });

  const handleChange = ({ currentTarget }) => {
    let subject = { ...blog.subject };
    subject[selectedLang] = currentTarget.value;
    setBlog((prev) => {
      return { ...prev, subject };
    });
  };

  const handlePhotoChange = ({ currentTarget }) => {
    setBlog((prev) => {
      return { ...prev, file: currentTarget.files[0] };
    });
  };

  const handleContentChange = (index, { currentTarget }) => {
    let content = [...blog.counterArray];
    content[index][currentTarget.name][selectedLang] = currentTarget.value;
    setBlog((prev) => {
      return { ...prev, counterArray: content };
    });
  };

  const handleDecrease = () => {
    if (blog.counterArray.length <= 1) return;
    let content = [...blog.counterArray];
    content.pop();
    setBlog((prev) => {
      return { ...prev, counterArray: content };
    });
  };

  const handleIncrease = () => {
    let content = [...blog.counterArray];
    content.push({
      header: {
        ar: "",
        en: "",
        fr: "",
      },
      body: {
        ar: "",
        en: "",
        fr: "",
      },
    });

    setBlog((prev) => {
      return { ...prev, counterArray: content };
    });
  };

  const handleSubmit = (published) => {
    if (published) setPublish(1);
    else setPublish(0);
    dispatch(createNeighborhoodReducer({ blog, published }))
      .unwrap()
      .then((_) => {
        setBlog({
          subject: {
            ar: "",
            en: "",
            fr: "",
          },
          counterArray: [
            {
              header: {
                ar: "",
                en: "",
                fr: "",
              },
              body: {
                ar: "",
                en: "",
                fr: "",
              },
            },
          ],
          file: "",
        });
        toast.success(
          "The neighborhood has been added successfully, only the added languages will be shown in the public website"
        );
        setPublish(-1);
        window.location = "/admin/dashboard/neighborhoods";
      });
  };

  const handleClick = (lang) => {
    setSelectedLang(lang);
  };

  const handleDeleteArticle = (index) => {
    let newArr = [...blog.counterArray];
    newArr = newArr.filter((_, elementIndex) => index !== elementIndex);
    setBlog((prev) => {
      return { ...prev, counterArray: newArr };
    });
  };

  return (
    <div className="single-blog create-blog">
      <ToastContainer />
      <h4>Create a neighborhood</h4>

      <div
        className="file-upload-wrapper"
        data-text={blog.file ? blog.file.name : "Select your image !"}
        style={{ height: "48px" }}
      >
        <input
          type="file"
          className="file-upload-field"
          onChange={handlePhotoChange}
          multiple
        />
      </div>

      <div style={{ marginTop: "0.7rem" }}>
        <Tabs
          defaultActiveKey="en"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          style={{ justifyContent: "space-around" }}
          onSelect={handleClick}
        >
          <Tab eventKey="en" title="English"></Tab>
          <Tab eventKey="fr" title="French"></Tab>
          <Tab eventKey="ar" title="Arabic"></Tab>
        </Tabs>
      </div>
      <input
        type="text"
        name="subject"
        value={blog.subject[selectedLang]}
        placeholder="Neighborhood subject"
        onChange={handleChange}
      />
      <section>
        <p>Neighborhood paragraphs</p>
        <button onClick={handleDecrease} style={{ marginTop: "0.5rem" }}>
          -
        </button>
        <button onClick={handleIncrease} style={{ marginTop: "0.5rem" }}>
          +
        </button>
      </section>
      {blog.counterArray.map((element, index) => (
        <section key={index}>
          <i
            onClick={() => handleDeleteArticle(index)}
            className="las la-trash-alt icon trash"
            style={{
              fontSize: "1.5rem",
              background: "#d1d1d1",
              padding: "5px",
              borderRadius: "20px",
              color: "red",
              display: "inline-block",
              float: "right",
              marginBottom: "0.4rem",
              marginTop: "0.3rem",
              cursor: "pointer",
            }}
          />
          <input
            type="text"
            placeholder="Paragraph header"
            value={element.header[selectedLang]}
            name="header"
            onChange={(e) => handleContentChange(index, e)}
          />
          <p
            style={{
              color: "gray",
              marginTop: "0.4rem",
            }}
          >
            To add a link surrond it with backticks like this
            `https://www.google.com`
          </p>
          <textarea
            placeholder="Paragraph body ..."
            rows={5}
            value={element.body[selectedLang]}
            name="body"
            onChange={(e) => handleContentChange(index, e)}
          ></textarea>
        </section>
      ))}
      <div>
        <button
          onClick={() => handleSubmit(false)}
          disabled={isLoading}
          className="save"
          style={{
            background: "transparent",
            color: "#e84042",
            marginRight: "0.4rem",
          }}
        >
          {isLoading && publish === 0 && (
            <div
              className="main-loader"
              style={{
                borderTopColor: "#e84042",
                borderBottomColor: "#e84042",
              }}
            ></div>
          )}
          {(!isLoading || publish !== 0) && <>Save as a draft</>}
        </button>
        <button
          onClick={() => handleSubmit(true)}
          disabled={isLoading}
          className="save"
        >
          {isLoading && publish === 1 && <div className="main-loader"></div>}
          {(!isLoading || publish !== 1) && <>Publish</>}
        </button>
      </div>
    </div>
  );
};

export default CreateNeighbourhood;
