import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useCallback } from "react";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useEffect } from "react";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmFzZWwtYmVzaGFycCIsImEiOiJjbGMzbmNmNXcwbDBoM3Zuem1teXhldmtpIn0.wtJ8ZWz84bXkMLMgXg9I8w";

const SingleMap = ({ listing }) => {
  const [viewport, setViewport] = useState({
    latitude: "",
    longitude: "",
    zoom: 14,
  });

  const [loaded, setLoaded] = useState(false);

  const mapRef = useRef();
  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  const [marker, setMarker] = useState({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    if (listing.longitude) {
      setLoaded(true);
      setViewport({
        latitude: +listing.latitude,
        longitude: +listing.longitude,
        zoom: 13,
      });
      setMarker({
        latitude: +listing.latitude,
        longitude: +listing.longitude,
      });
    }
  }, [listing.longitude, listing.latitude]);

  return (
    <div style={{ height: "75vh" }}>
      {loaded && (
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/streets-v12"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          scrollZoom={false}
        >
          <NavigationControl style={{ position: "absolute", top: 0 }} />
          <Marker longitude={marker.longitude} latitude={marker.latitude}>
            <div className="marker temporary-marker">
              <span></span>
            </div>
          </Marker>
        </MapGL>
      )}
    </div>
  );
};
export default SingleMap;
