import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteLeadReducer, getLeadsReducer } from "../../adminSlice";
import { useState } from "react";

const Leads = (props) => {
  const blogs = useSelector((state) => state.admin.leads);
  const isLoading = useSelector((state) => state.admin.blodLoading);
  const dispatch = useDispatch();
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const handleClick = (element) => {
    props.history.push({
      pathname: "/admin/dashboard/leads/" + element.id,
      state: { element },
    });
  };

  useEffect(() => {
    dispatch(getLeadsReducer());
  }, [dispatch]);

  const handleDelete = (element, index) => {
    setDeleteIndex(index);
    dispatch(deleteLeadReducer(element.id))
      .unwrap()
      .then((_) => {
        setDeleteIndex(-1);
        window.location = window.location.pathname;
      });
  };

  const handleForward = () => {
    props.history.push({
      pathname: "/admin/dashboard/create-lead",
    });
  };

  const handleMessage = (element, email) => {
    props.history.push({
      pathname: "/admin/dashboard/leads/message",
      state: { element, email },
    });
  };

  return (
    <div className="blogs">
      <section>
        <button onClick={handleForward}>Add new lead</button>
      </section>
      <div className="data">
        <div className="content">
          {blogs.map((element, index) => (
            <div
              className="single-data single-lead"
              key={index}
              style={{ flexDirection: "column !important" }}
            >
              <section style={{ flexBasis: "100% !important" }}>
                {(!isLoading || (isLoading && deleteIndex !== index)) && (
                  <i
                    className="las la-trash-alt icon trash"
                    onClick={() => handleDelete(element, index)}
                  />
                )}
                {isLoading && deleteIndex === index && (
                  <div className="main-loader"></div>
                )}
                <h4
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleClick(element)}
                >
                  {element.name}
                </h4>
                <p>{element.email}</p>
                <p>{element.description}</p>
                <section>
                  <button
                    onClick={() => handleMessage(element, false)}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <i
                      className="las la-sms"
                      style={{ fontSize: "1.3rem", marginRight: "10px" }}
                    />
                    Send SMS
                  </button>
                  <button
                    onClick={() => handleMessage(element, true)}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <i
                      className="las la-envelope"
                      style={{ fontSize: "1.5rem", marginRight: "10px" }}
                    />
                    Send email
                  </button>
                </section>
              </section>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Leads);
