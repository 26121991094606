import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { loginApi } from "../../../api/authApis";
import loginImg from "../../../images/login.svg";
import "../auth.css";

const Login = () => {
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = ({ currentTarget }) => {
    setLogin((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleSubmit = async () => {
    try {
      if (!login.email || !login.password) {
        return toast.error("Fill the email and the password please");
      }
      setIsLoading(true);
      let { data } = await loginApi(login);
      setIsLoading(false);
      if (data.data === "wrong password") throw new Error("wron password");
      localStorage.setItem("token", data.data);
      localStorage.setItem("time", new Date().getTime());
      window.location = "/admin/dashboard";
    } catch (err) {
      setIsLoading(false);
      toast.error("Wrong email or password");
    }
  };

  const handleClick = (key) => {
    if (key.keyCode === 13) handleSubmit();
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login">
        <img src={loginImg} alt="login" />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={login.email}
          onChange={handleChange}
          onKeyUp={handleClick}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={login.password}
          onChange={handleChange}
          onKeyUp={handleClick}
        />
        <button onClick={handleSubmit} disabled={isLoading}>
          {isLoading && <div className="main-loader"></div>}
          {!isLoading && <>Send</>}
        </button>
      </div>
    </div>
  );
};

export default Login;
