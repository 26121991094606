const SixthPage = ({
  handleIncrement,
  handleDecrement,
  listing,
  handleChange,
}) => {
  return (
    <div className="sixth-page">
      <h5>Composition</h5>
      <section style={{ marginBottom: 0 }}>
        <table>
          <thead>
            <tr>
              <th>Unit Type</th>
              <th>Total</th>
              <th>Total Vacant</th>
              <th>Surface</th>
              <th>Description</th>
            </tr>
          </thead>

          <tbody>
            {listing.unitComposition.map((element, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name="unitType"
                    value={element.unitType}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="unitTotal"
                    value={element.unitTotal}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="totalVacant"
                    value={element.totalVacant}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="surface"
                    value={element.surface}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="description"
                    value={element.description}
                    onChange={(e) => handleChange(index, e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className="buttons"
          style={{
            justifyContent: "center",
            marginTop: "0.5rem",
            marginBottom: "0",
          }}
        >
          <button style={{ marginRight: "0.6rem" }} onClick={handleDecrement}>
            -
          </button>
          <button onClick={handleIncrement}>+</button>
        </div>
      </section>
    </div>
  );
};

export default SixthPage;
