import React from "react";
import { useState } from "react";

import CommonModal from "./CommonModal";

import "./common.css";
import { emailSenderApi } from "../../api/emailApi";
import { getLanguageObject } from "../utils/languageDetector";
import { useSelector } from "react-redux";
import profile from "../../images/profile.jpg";
import { toast, ToastContainer } from "react-toastify";

const JoulnarCard = ({ hideEmail, reference, referenceInfo }) => {
  const lang = getLanguageObject().card;

  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    phone: "",
    message: lang.hello,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = ({ currentTarget }) => {
    setEmailData((prev) => {
      return { ...prev, [currentTarget.name]: currentTarget.value };
    });
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await emailSenderApi(emailData);
      setIsLoading(false);
      setEmailData({
        name: "",
        email: "",
        phone: "",
        message: lang.hello,
      });
      toast.success("Your email has been sent successfully");
    } catch (err) {
      setIsLoading(false);
      toast.error("Error!, try again please");
    }
  };

  const image = useSelector((state) => state.admin.images);
  const loadedImages = useSelector((state) => state.admin.loadedImages);

  return (
    <div className="joulnar-card">
      <ToastContainer />
      {reference && (
        <div className="reference">
          <p className="reference">
            <span>Reference :</span>
            <span>{referenceInfo}</span>
          </p>
        </div>
      )}
      {loadedImages && (
        <img src={image.profile ? image.profile : profile} alt="Joulnar" />
      )}
      <h4 style={{ color: "#E84042" }}>
        Joulnar El Husseini
        <br />
        McCormick
      </h4>
      <h6>{lang.broker}</h6>
      <section className="social-media">
        <a href={window.location.pathname}>
          <i className="las la-globe-europe" />
        </a>
        <a href="https://www.facebook.com/people/Joulnar-Mccormick-Immobilier/100063695612255/">
          <i className="lab la-facebook" />
        </a>
        <a href="https://www.linkedin.com/in/joulnar-mccormick-96a72917/">
          <i className="lab la-linkedin" />
        </a>
        <a href="https://www.instagram.com/joulnar_immobilier/">
          <i className="lab la-instagram" />
        </a>
      </section>
      <section className="phone">
        <a href="tel:514 970-3704" style={{ color: "#E32B36" }}>
          <i className="las la-mobile" />
          <span style={{ color: "#E32B36" }}>514 970-3704</span>
        </a>
      </section>
      {!hideEmail && (
        <section className="email">
          <CommonModal
            heading={lang.contact}
            handleclick={handleClick}
            isloading={isLoading}
          >
            <p style={{ background: "#E32B36" }}>
              <i className="las la-envelope" /> {lang.send}
            </p>
            <div className="email-body">
              <input
                type="text"
                name="name"
                value={emailData.name}
                onChange={handleChange}
                placeholder={lang.name}
              />
              <input
                type="email"
                placeholder={lang.email}
                name="email"
                value={emailData.email}
                onChange={handleChange}
              />
              <input
                type="phone"
                placeholder={lang.phone}
                name="phone"
                value={emailData.phone}
                onChange={handleChange}
              />
              <textarea
                rows={10}
                name="message"
                value={emailData.message}
                onChange={handleChange}
              ></textarea>
            </div>
          </CommonModal>
        </section>
      )}
    </div>
  );
};

export default JoulnarCard;
